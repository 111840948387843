<template>
  <v-row>
    <v-col cols="12" md="3">
      <span
        class="text-subtitle-2 font-weight-bold mt-1 mr-2"
        style="color: #4d5358"
      >
        {{ text }}
      </span>
    </v-col>
    <v-col cols="12" md="6">
      <label :for="inputId" class="custom-file-box">
        <v-img
          src="../../assets/file-input.png"
          height="40"
          width="40"
          class="file-icon"
        />
        <span class="text-click">Click para subir</span
        ><span> o arrastra la imagen</span><br />
        <small>{{ size }}</small>
        <p class="text-selected" v-if="selectedFile">{{ selectedFile.name }}</p>
        <v-img
          src="../../assets/file-input-pdf.png"
          height="40"
          width="40"
          class="file-icon-right"
        />
      </label>
      <v-file-input
        v-model="selectedFile"
        :id="inputId"
        prepend-icon=""
        accept="image/png, image/jpeg"
        hide-input
        @change="handleFileChange"
      ></v-file-input>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "InputFile",
  props: {
    typeFile: String,
    text: String,
    size: String,
    inputId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedFile: null,
    };
  },
  methods: {
    handleFileChange(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.$emit("file-loaded-base64", reader.result, this.typeFile);
        };
        this.$emit("file-loaded", file, this.typeFile);

        this.selectedFile = file;
      }
    },
  },
};
</script>

<style scoped>
.custom-file-box {
  border: 2px solid #00a5df;
  border-radius: 16px;
  padding: 20px;
  text-align: center;
  background-color: white;
  color: #4d5358;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.custom-file-box p {
  margin: 8px 0;
}

#file-input {
  display: none;
}

.text-click {
  color: #00a5df;
  font-weight: bold;
}

.text-selected {
  color: #00a5df;
  font-size: 12px;
}

.file-icon {
  display: block;
  margin: 0 auto 8px auto;
}

.file-icon-right {
  display: block;
  margin-left: auto;
}
</style>
