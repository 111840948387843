<template>
  <div class="pt-5">
    <v-data-table
      :items="alianzas"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin asesores para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:top>
        <v-row style="padding: 15px">
          <v-col cols="12" md="8">
            <h2>Resultados de la búsqueda</h2>
          </v-col>
          <v-col cols="12" md="4" style="text-align: end">
            <download-csv :data="columnsReport" name="polizas_tabla.csv">
              <v-btn color="transparent" elevation="0" small>
                <span style="color: #0070c0">Descargar</span>
                <v-icon small right class="mr-2" color="#0070C0"
                  >mdi-file-pdf-box</v-icon
                >
              </v-btn>
            </download-csv>
          </v-col>
        </v-row>
      </template>
      <template #item.id="{ item }">
        {{ item.id ? item.id : "Sin ID" }}
      </template>
      <template v-slot:item.status="{ item }">
        <div v-if="item.status == -1">
          <v-chip color="red" dark>Baja</v-chip>
        </div>
        <div v-if="item.status == 0">
          <v-chip color="blue" dark>Nueva</v-chip>
        </div>
        <div v-if="item.status == 1">
          <v-chip color="green" dark>Activa</v-chip>
        </div>
        <div v-if="item.status == 2">
          <v-chip color="amber darken-2" dark>Inactiva</v-chip>
        </div>
      </template>
      <template #item.nombre="{ item }">
        <span>{{
          item.nombreCompleto ? item.nombreCompleto : "Sin Nombre"
        }}</span>
      </template>
      <template #item.celular="{ item }">
        <span>{{ item.celular ? item.celular : "Sin Celular" }}</span>
      </template>
      <template #item.correoElectronico="{ item }">
        <span>{{ item.correo ? item.correo : "Sin Correo" }}</span>
      </template>
      <template #item.fechaRegistro="{ item }">
        <span>{{ item.fechaRegistro ? item.fechaRegistro : "Sin Fecha" }}</span>
      </template>
      <template v-slot:item.uuid="{ item }">
        <v-btn icon x-small @click="editItem(item.uuid)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <!-- <template #item.ver="{ item }">
        <v-btn icon x-small @click="editItem(item.uuid)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template> -->

      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    alianzas: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Estatus",
          value: "status",
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Celular",
          value: "celular",
        },
        {
          text: "Correo Electrónico",
          value: "correoElectronico",
        },
        {
          text: "Fecha de Registro",
          value: "fechaRegistro",
        },
        {
          text: "Ver Agente",
          value: "uuid",
        },
      ],
      columnsReport: [],
    };
  },

  methods: {
    editItem(item) {
      console.log(item);
      this.$router.push(`/usuario/ver-micuenta/${item}`);
    },
    formatReport() {
      this.columnsReport = this.alianzas.map((e) => ({
        ID: e.id ?? "Sin llenar",
        Estatus: e.estatus ?? "Sin llenar",
        Nombre: e.nombre ?? "Sin llenar",
        Celular: e.celular ?? "Sin llenar",
        "Correo Electrónico": e.correo ?? "Sin llenar",
        "Fecha de Registro": e.fechaRegistro ?? "Sin llenar",
      }));
    },
  },

  watch: {
    alianzas: {
      handler() {
        this.formatReport();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}
</style>
