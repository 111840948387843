<template>
  <div>
    <v-autocomplete
      v-model="correoElectronico"
      :label="customLabelText"
      :items="prediccionesCorreo"
      :filter="filtroCorreo"
      :rules="finalRules"
      :append-icon="null"
      :readonly="readOnly"
      outlined
      color="#00a7e4"
      :dense="isDense"
      @blur="touched = true"
    ></v-autocomplete>
  </div>
</template>

<script>
import {
  dominiosComunes,
  validarDominioComun,
} from "@/helpers/validacionDominiosComunes.js";

export default {
  data() {
    return {
      busquedaCorreo: "",
      correoElectronico: null,
      touched: false,
      rules: {
        emailRules: [
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !!value && value.length
              ? pattern.test(value) || "Dirección de correo incorrecta"
              : true;
          },
        ],
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
    };
  },
  props: {
    emailEdit: {
      type: String,
      default: "",
    },

    customLabelText: {
      type: String,
      default: "Correo Electrónico",
    },

    mustHasValue: {
      type: String,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // correoElectronico(val) {
    //   this.$emit("emailUpdate", val);
    // },
    correoElectronico(val) {
      if (!val) {
        this.correoElectronico = null; // Forzar a null si está vacío
      }
      this.$emit("emailUpdate", val);
    },
    emailEdit() {
      this.fillEmail();
    },
  },
  methods: {
    validarDominioComun,
    filtroCorreo(item, queryText, itemText) {
      this.busquedaCorreo = queryText;
      return itemText.includes(queryText);
    },
    fillEmail() {
      this.correoElectronico = this.emailEdit;
      this.busquedaCorreo = this.emailEdit;
    },
  },
  mounted() {
    this.fillEmail();
  },
  computed: {
    prediccionesCorreo() {
      const correo = this.busquedaCorreo || ""; // Asegúrate de que siempre sea una cadena
      if (!correo.length) return [""];
      if (correo.includes("@")) return [correo];
      return dominiosComunes.map((e) => `${correo}@${e}`);
    },
    
    finalRules() {
      const result = [...this.rules.emailRules, this.validarDominioComun];

      // Solo agregar la regla de "requerido" si el campo ha sido tocado
      if (this.touched) {
        result.push(...this.requiredRule);
      }

      if (this.mustHasValue)
        result.push(
          (v) => v == this.mustHasValue || "Los correos no coinciden"
        );

      return result;
    },
  },
};
</script>
