<template>
  <div class="pointer-card" @click="viewSection">
    <common-card :style="{ backgroundColor: color ? color : '#0070C0' }">
      <v-row class="my-3" align="center" style="padding: 16px">
        <v-col cols="6">
          <span class="title">{{ title ? title : "Total de asesores" }}</span>
        </v-col>
        <v-col cols="4" class="d-flex justify-center">
          <span class="count-dashboard">{{
            total ? formatNumber(total) : 0
          }}</span>
        </v-col>
        <v-col v-if="arrow" cols="2" class="d-flex align-center justify-center">
          <v-img
            src="../../../../../../assets/arrow-dashboard.png"
            height="24"
            width="24"
          />
        </v-col>
      </v-row>
    </common-card>
  </div>
</template>
<script>
import CommonCard from "../../../../../../components/commonComponents/CommonCard.vue";
export default {
  components: { CommonCard },
  name: "CardDashboard",
  props: {
    title: String,
    total: String,
    color: String,
    isTotalPrimaNeta: Boolean,
    view: Function,
    arrow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      alianzaName: "[Nombre_Alianza]",
    };
  },
  methods: {
    viewSection() {
      this.$emit("view");
    },
    formatNumber(value) {
      if (this.isTotalPrimaNeta) {
        const num = parseFloat(value);
        if (num >= 1000000) {
          return `$${(num / 1000000).toFixed(1)} M`;
        } else if (num >= 1000) {
          return `$${(num / 1000).toFixed(1)} K`;
        }
        return `$${num.toFixed(2)}`;
      } else {
        return value;
      }
    },
  },
};
</script>

<style scoped>
.count-dashboard {
  font-size: 28px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
}
.title {
  color: white;
  font-size: 18px !important;
  font-weight: bold;
  line-height: 1.2;
}
.pointer-card {
  cursor: pointer;
}
</style>
