<template>
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel>
      Buscar
    </template>
    <v-expansion-panel-content>
      <FiltersCotizaciones
        @searchUpdated="getCotizaciones"
        :currentFilters="filtros"
        :loading="loading"
      ></FiltersCotizaciones>
      <hr />
      <TableCotizaciones
        :loading="loading"
        :solicitudes="solicitudes"
      ></TableCotizaciones>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import FiltersCotizaciones from "./FiltersCotizaciones.vue";
import TableCotizaciones from "./TableCotizaciones.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    CommonExpansionPanel,
    FiltersCotizaciones,
    TableCotizaciones,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
    };
  },
  props: {
    contactData: Object,
    correosDataFormat: Object,
    routesContactData: Object,
  },
  methods: {
    getCotizaciones(body) {
      if (String(window.location.pathname).includes("/alianza/perfil/")) {
        body.alianza_id = (this.$route.params.alianza_id)
        body.grouped = true
      }
      body.alianza_id = this.$route.params.alianza_id || localStorage.alianzaId;

      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      mainAxios
        .post(`/v1/cotizacion/filtros`, body, requestConfig)
        .then(({ data }) => {
          this.loading = false;
          if (data.length) this.solicitudes = data;
          else this.solicitudes = [];
          this.filtros = body;
        });
        this.loading = false;
    },

    getTodasLasSolicitudes() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get(`/v1/solicitud/info/status/1`, requestConfig)
        .then(({ data }) => {
          this.todasLasSolicitudes = data;
        });
    },
  },
  mounted() {
    if (String(window.location.pathname).includes("/alianza/perfil/")) {
      this.getCotizaciones({});
    }
  },
};
</script>
