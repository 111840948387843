<template>
  <CommonExpansionPanel :currentPanel="currentPanel">
    <template v-slot:title-panel>
      Datos del cliente
    </template>
    <v-expansion-panel-content>
      <v-form ref="secoundStepForm">
        <v-row>
          <v-col cols="12" m="12" md="6" lg="4" xl="4">
            <v-autocomplete
              label="Tipo de persona"
              outlined
              v-model="data.regimen"
              primary
              :items="regimentList"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4" v-if="data.regimen == 'Moral'">
            <v-text-field
              label="Razón Social"
              outlined
              v-model="data.razon_social"
              color="#00a7e4"
              type="text"
              :rules="requiredRuleCaracteres"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-text-field
              label="Nombre(s)"
              outlined
              v-model="data.nombre"
              primary
              color="#00a7e4"
              type="text"
              :rules="requiredRuleCaracteres"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-text-field
              label="Apellidos Paterno"
              outlined
              v-model="data.apellidoPaterno"
              primary
              color="#00a7e4"
              type="text"
              :rules="requiredRuleCaracteres"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Apellidos Materno"
              outlined
              v-model="data.apellidoMaterno"
              primary
              color="#00a7e4"
              type="text"
              :rules="requiredRuleCaracteres"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Fecha de Nacimiento"
              outlined
              v-model="data.fechaNacimiento"
              primary
              color="#00a7e4"
              type="date"
              :rules="requiredRuleDate"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Edad"
              outlined
              v-model="data.edad"
              primary
              color="#00a7e4"
              type="text"
              readonly
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="RFC"
              outlined
              v-model="data.rfc"
              primary
              :rules="rulesRfc"
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Género"
              outlined
              v-model="data.genero"
              :items="generoList"
              primary
              color="#00a7e4"
              type="text"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Estado Civil"
              outlined
              v-model="data.estadoCivil"
              primary
              :items="['Soltero(a)', 'Casado(a)', 'Unión Libre']"
              color="#00a7e4"
              type="text"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Teléfono"
              outlined
              v-model="data.telefono"
              primary
              color="#00a7e4"
              counter="10"
              :rules="[celularRule, longDigitValidation(10)]"
              :maxLength="10"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <common-email-autocomplete
              :emailEdit="data.correo"
               @emailUpdate="(e) => (data.correo = e)"
            >
            </common-email-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Codigo postal"
              outlined
              v-model="data.codigoPostal"
              primary
              color="#00a7e4"
              type="text"
              counter="5"
              :rules="[$rules.numeric, longDigitValidation(5)]"
              :maxLength="5"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Calle"
              outlined
              v-model="data.calle"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Numero exterior"
              outlined
              v-model="data.num_exterior"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Numero interior"
              outlined
              v-model="data.num_interior"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Colonia"
              outlined
              v-model="data.colonia"
              :items="colonias"
              item-text="nombreColonia"
              item-value="nombreColonia"
              primary
              color="#00a7e4"
              type="text"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Ciudad"
              outlined
              v-model="data.ciudad"
              primary
              color="#00a7e4"
              readonly
              type="text"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Estado"
              placeholder="Requerido"
              :items="estados"
              item-text="name"
              item-value="id"
              readonly
              outlined
              v-model="data.estado"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
      <v-row style="text-align: end;">
        <v-col cols="12">
          <v-btn
            rounded
            dark
            class="common-botton"
            @click="$emit('goBack')"
            style="margin-right: 10px;"
          >
            <span>
              Anterior
            </span>
          </v-btn>
          <v-btn rounded dark class="common-botton" @click="validateStep">
            <span>
              Guardar
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "../../../commonComponents/CommonExpansionPanel.vue";
import { getUbicacionesCP } from "@/services/codigoPostal/CodigoPostal.js";
import RfcFacil from "rfc-facil";
import moment from "moment";
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";

export default {
  components: {
    CommonExpansionPanel,
    CommonEmailAutocomplete
  },
  props: {
    currentPanel: {
      type: Number,
      default: 0,
    },
    cliente: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      data: {
        nombre: "",
        apellidoMaterno: "",
        apellidoPaterno: "",
        regimen: "Física",
        rfc: "",
        telefono: "",
        fechaNacimiento: "",
        genero: "",
        correoElectronico: "",
        codigoPostal: "",
        calle: "",
        num_exterior: "",
        num_interior: "",
        colonia: "",
        ciudad: "",
        estado: "",
        usuario: "",
        globalInteractionId: "",
        cliente_uuid: "",
        razon_social: "",
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      campañas: [],
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      regimentList: ["Física", "Moral"],
      rulesRfc: [
        (v) => !!v || "Este campo es requerido",
        (v) => {
          if (v == null || v.trim() == "") {
            return true;
          }
          let value = v.toUpperCase();
          // hay un error en la validacion de a rfc
          const regex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?(([A-Z\d]{2})([A\d])|)$/;
          let correcto = value.match(regex); //SE CREA UN OBJETO CON LA EXPRESIÓN REGULAR

          return !!correcto || "La RFC no es valida";
        },
        (v) => !(v == "XAXX010101000") || "El RFC no puede ser 'XAXX010101000'",
        (v) => !(v == "XAXX010101") || "El RFC no  puede ser 'XAXX010101'",
        (v) =>
          v.length === 10 ||
          v.length === 13 ||
          "El RFC debe tener 10 o 13 caracteres",
      ],
      generoList: ["Masculino", "Femenino"],
      colonias: [],
      longDigitValidation(number) {
        return (v) =>
          !v ||
          v.length == number ||
          "Debe proporcionar " + number + " digitos";
      },
      requiredRuleCaracteres: [
        (v) => !!v || "Este campo es requerido", // Verifica que el campo no esté vacío
        (v) => (v && v.length >= 3) || "Mínimo 3 caracteres", // Mínimo de 3 caracteres
        (v) =>
          /^[a-zA-Z\s]+$/.test(v) || "No se permiten caracteres especiales", // Solo letras y espacios
      ],
      requiredRuleDate: [
        (v) => !!v || "La fecha es requerida.",
        (v) =>
          this.isPastDate(v) ||
          "La fecha no puede ser hoy ni una en el futuro.",
        (v) =>
          this.isWithinLast100Years(v) ||
          "La fecha no puede ser mayor a 100 años.",
      ],
      celularRule: (value) => {
        // Verifica que el número tenga exactamente 10 dígitos
        const formato = /^\d{10}$/;
        if (!formato.test(value)) {
          return "El teléfono debe tener 10 dígitos";
        }

        // Evita secuencias numéricas ascendentes o descendentes como "1234567890" o "9876543210"
        const secuenciaAscendente = /^(\d)(\d)\d{8}$/;
        if (secuenciaAscendente.test(value)) {
          const [, a, b] = secuenciaAscendente.exec(value);
          if (+b === +a + 1) {
            return "Teléfono no válido (secuencia numérica)";
          }
        }
        // Verifica que no tenga todos los mismos dígitos, como "1111111111" o "0000000000"
        const soloRepetidos = /^(\d)\1{9}$/;
        if (soloRepetidos.test(value)) {
          return "Teléfono no válido (número repetitivo)";
        }
        // Si ninguna de las reglas anteriores se cumple, el número es válido
        return true;
      },
    };
  },
  watch: {
    "data.fechaNacimiento"(newValue) {
      if (newValue != "" && newValue != null) {
        var years = moment(newValue, "YYYY-MM-DD");
        var today = moment().format("YYYY-MM-DD");
        var diference = moment(today, "YYYY-MM-DD").diff(
          moment(years, "YYYY-MM-DD"),
          "years"
        );
        this.data.edad = diference;
      }
      this.actualizarRfc();
    },
    "data.nombre"() {
      this.actualizarRfc();
    },
    "data.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "data.apellidoMaterno"() {
      this.actualizarRfc();
    },
    async "data.codigoPostal"(val) {
      if (typeof val != "undefined") {
        if (val.length == 5) {
          const response = await getUbicacionesCP(val);
          if (response.listaColonias.length >= 1) {
            this.cpFlag = true;
            this.colonias = response.listaColonias;
            this.data.estado = Number(response.nEstado);
            this.data.ciudad = response.nombreMunicipio;
          } else if (response.error == "") {
            alert("No existe ese codigo postal");
          }
        }
      }
    },
    "dataOrigen.uuid_data_segurify"() {
      this.getdata();
      this.getCanales();
      this.getRamos();
      this.getCampanias();
    },
    cliente() {
      this.fillData();
    },
  },

  methods: {
    validateStep() {
      if (this.$refs.secoundStepForm.validate()) {
        this.$emit("goToSave", this.data);
      }
    },
    fillData() {
      var keys = Object.keys(this.data);
      keys.map((e) => {
        if (typeof this.cliente[e] != "undefined") {
          this.data[e] = this.cliente[e];
        }
      });

      this.data.telefono =
        JSON.parse(localStorage.getItem("jsonLlamada")).numero ?? "";
      this.data.usuario = localStorage.getItem("id") ?? "";
      this.data.globalInteractionId =
        JSON.parse(localStorage.getItem("jsonLlamada")).global_id ?? "";
      this.data.cliente_uuid = this.cliente.uuid ?? "";
      this.data.cliente_id = this.cliente.id ?? "";
    },
    actualizarRfc() {
      console.log("si entre");
      if (
        !this.data.nombre ||
        !this.data.apellidoPaterno ||
        !this.data.apellidoMaterno ||
        !this.data.fechaNacimiento
      )
        return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.data.nombre,
        firstLastName: this.data.apellidoPaterno,
        secondLastName: this.data.apellidoMaterno,
        day: this.data.fechaNacimiento.split("-")[2],
        month: this.data.fechaNacimiento.split("-")[1],
        year: this.data.fechaNacimiento.split("-")[0],
      });
      this.data.rfc = rfc;
    },
    isPastDate(value) {
      const selectedDate = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Ignorar el tiempo para la comparación
      return selectedDate < today;
    },
    isWithinLast100Years(value) {
      const selectedDate = new Date(value);
      const date100YearsAgo = new Date();
      date100YearsAgo.setFullYear(date100YearsAgo.getFullYear() - 100);
      return selectedDate >= date100YearsAgo;
    },
  },
  mounted() {
    this.fillData();
  },
};
</script>
