<template>
  <v-container>
    <v-row>
      <v-col class="col-12" md="6">
        <span class="gray mb-32"
          >¿Qué tipo de cuatripatitas tienes en casa?</span
        >
        <v-radio-group
          v-model="initCotizacion.tipoMascota"
          row
          :rules="requiredRule"
        >
          <v-radio
            v-for="(opc, index) in species"
            :key="index + '-specie'"
            :label="opc.txt_desc_especie"
            :value="opc"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="col-12 mb-32" md="6">
        <v-text-field
          v-model.trim="initCotizacion.nombreMascota"
          label="Nombre de tu mascota*"
          outlined
          autofocus
          :rules="requiredRule"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="mb-32">
        <v-text-field
          v-model.trim="initCotizacion.nombreDuexo"
          label="Nombre*"
          outlined
          :rules="requiredRule"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="mb-32">
        <v-text-field
          v-model.trim="initCotizacion.apellidoPaterno"
          label="Apellido Paterno*"
          outlined
          :rules="requiredRule"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="mb-32">
        <v-text-field
          v-model.trim="initCotizacion.apellidoMaterno"
          label="Apellido Materno"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="mb-32">
        <common-email-autocomplete
          :emailEdit="initCotizacion.correo"
          @emailUpdate="(e) => (initCotizacion.correo = e)"
        ></common-email-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.trim="initCotizacion.telefono"
          label="Número telefónico*"
          type="tel"
          outlined
          maxlength="10"
          :rules="requiredRule"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" v-if="!isAgente">
        <v-autocomplete
          label="Agente"
          :items="agentesConRamos"
          item-text="nombre"
          item-value="id"
          v-model="initCotizacion.agenteId"
          placeholder="Requerido"
          outlined
          color="#00a7e4"
          :rules="requiredRule"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mainAxios } from "../../../../mainAxios";
import CommonEmailAutocomplete from '@/components/commonComponents/CommonEmailAutocomplete.vue';
export default {
  name: "FormularioMascota",
  props: {
    initCotizacion: Object,
    speciesParent: Array
  },
  components:{
    CommonEmailAutocomplete
  },
  data() {
    return {
      species: this.speciesParent,
      requiredRule: [(v) => !!v || "El campo es requerido"],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      agentes:[],
      ramosIdsUsuario:[]
    };
  },
  methods: {
    
    async initLoginInter() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      const response = await mainAxios.get("/v1/mascota/init", config);
      sessionStorage.interToken = response.data.data.accessToken;
      this.loading = false;
      return true;
    },
    getUsuarios() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/uuid/${localStorage.agenteUuid}`,config).then((response)=>{
       this.ramosIdsUsuario = response.data.ramos
      });
    },
    tieneRamos(agente) {
      const ramosAgente = agente.ramosList.map(ramo => ramo.id);
      return this.ramosIdsUsuario.some(id => ramosAgente.includes(id));
    },
    getAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList ? element.canalesList : [],
            ramosList: element.ramosList ? element.ramosList : [],
            alianzaId: element.alianzaId ? element.alianzaId: null
          });
        });
      });
    },
    getCurrentAlianzaId(){
      var currentAgente = this.agentes.find(
          (e) => e.id == this.initCotizacion.agenteId
        );
      this.initCotizacion.alianzaId = currentAgente ? currentAgente.alianzaId : null
    },
  },
  async created() {
    this.species= this.speciesParent
  },
  watch:{
    speciesParent:function(newSpecies){
      this.species = newSpecies;
    },
    "initCotizacion.agenteId"(){
      this.getCurrentAlianzaId()
    }
  },
  computed:{
    agentesConRamos() {
      return this.agentes.filter(agente => this.tieneRamos(agente));
    },
    isAgente() {
      return this.rol == "AGENT";
    },
  },
  mounted(){
    this.getAgentes()
    this.getUsuarios()
    if (this.rol == "AGENT") this.initCotizacion.agenteId = localStorage.id;
  }
};
</script>

<style scoped></style>
