<template>
  <v-dialog v-model="dialog" max-width="390">
    <v-card v-if="step === 1" class="card-dialog">
      <v-col align="end" class="pb-0 pt-0">
        <v-btn icon @click="cancelarRegistro" aria-label="Cerrar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
      <v-card-title class="headline text-center ma-0"
        >Confirmar registro</v-card-title
      >
      <v-card-text>
        <p class="text-center">
          ¿Estás seguro de que los datos del asesor son correctos?
        </p>
        <v-row>
          <v-col cols="6" class="pb-0">
            <p class="titleKey">Nombre:</p>
            <p class="value">{{ asesor.nombre || "[Name]" }}</p>

            <p class="titleKey">Apellido Paterno:</p>
            <p class="value">
              {{ asesor.apellidoPaterno || "[ApellidoPaterno]" }}
            </p>

            <p class="titleKey">Apellido Materno:</p>
            <p class="value">
              {{ asesor.apellidoMaterno || "[ApellidoMaterno]" }}
            </p>

            <p class="titleKey">Género:</p>
            <p class="value">
              {{ asesor.sexo === 1 ? "Masculino" : "Femenino" || "[Género]" }}
            </p>

            <p class="titleKey">Fecha de Nacimiento:</p>
            <p class="value">{{ asesor.fechaNacimiento || "[00/00/00]" }}</p>

           
          </v-col>

          <v-col cols="6" class="pb-0">
            <p class="titleKey">Estado de Nacimiento:</p>
            <p class="value">
              {{
                obtenerLabelEstadoPorId(
                  asesor.nacimientoEstadoId,
                  catalogoEstados
                )
              }}
            </p>
            <!-- <p class="titleKey">Teléfono de Contacto:</p>
            <p class="value">{{ asesor.telefono || "[0000000000]" }}</p> -->

            <p class="titleKey">Celular de Contacto:</p>
            <p class="value">{{ asesor.celular || "[0000000000]" }}</p>

            <p class="titleKey">RFC:</p>
            <p class="value">{{ asesor.rfc || "[000000000000]" }}</p>

            <p class="titleKey">Correo Electrónico:</p>
            <p class="value">
              {{ asesor.emails[0].correo || "[mail.com.mx]" }}
            </p>
          </v-col>
        </v-row>
        <!-- <p>Ramo: {{ obtenerLabelsPorIds(asesor.ramo, catalogRamos) }}</p> -->
      </v-card-text>

      <v-card-actions class="actions">
        <v-btn rounded color="primary" @click="confirmarRegistro">
          Confirmar registro
        </v-btn>

        <!-- <v-icon-text class="cancel-btn" @click="cancelarRegistro">
          <v-icon style="margin-right: 5px"> mdi-arrow-left </v-icon> Cancelar
        </v-icon-text> -->
      </v-card-actions>
    </v-card>
    <v-card v-if="step === 2" class="card-dialog">
      <v-col align="end" class="pb-0 pt-0">
        <v-btn icon @click="cancelarRegistro" aria-label="Cerrar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
      <v-card-title class="headline text-center"
        >¡Registro exitoso!</v-card-title
      >
      <v-card-text>
        <v-col class="pa-0" cols="12" align="center" justify="center">
          <v-img class="loader" :src="loader"></v-img>
          <!-- <v-icon size="64" color="#3BBBEA">mdi-check-circle</v-icon> -->
        </v-col>
        <p class="text-center">
          El registro de {{ asesor.nombre }} {{ asesor.apellidoPaterno }}
          {{ asesor.apellidoMaterno }} ha sido guardado correctamente.
        </p>
        <p class="text-center">¿Deseas registrar a otro asesor?</p>
      </v-card-text>
      <v-card-actions class="actions">
        <v-btn rounded color="primary" @click="setearValores"
          >Registrar otro asesor</v-btn
        >
        <!-- <v-icon-text class="cancel-btn" @click="cancelarRegistro">
          Cancelar
        </v-icon-text> -->
      </v-card-actions>
    </v-card>
    <v-card v-if="step === 3" class="card-dialog">
      <v-card-title class="headline text-center"
        >Error en el registro</v-card-title
      >
      <v-card-text>
        <v-col cols="12" align="center" justify="center">
          <v-icon size="64" color="#3BBBEA">mdi-alert-circle-outline</v-icon>
        </v-col>
        <p class="text-center">
          <strong>{{ errorMessage }}.</strong> <br />
          El registro de {{ asesor.nombre }} {{ asesor.apellidoPaterno }}
          {{ asesor.apellidoMaterno }} no se pudo completar. Por favor, intente
          nuevamente o consulte con el servicio técnico para asistencia.
        </p>
      </v-card-text>
      <v-card-actions class="actions">
        <v-btn rounded color="primary" @click="confirmarRegistro"
          >Intentar nuevamente</v-btn
        >
        <v-icon-text class="cancel-btn" @click="cancelarRegistro">
          Cancelar
        </v-icon-text>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import loader from "../../../../assets/Loaderverify.gif";
export default {
  props: {
    dialog: Boolean,
    asesor: Object,
    step: Number,
    errorMessage: String,
  },
  data() {
    return {
      loader,
      internalDialog: this.dialog,
      catalogRamos: [
        { id: 1, label: "Autos" },
        { id: 2, label: "Salud" },
        { id: 3, label: "Daños" },
        { id: 4, label: "Fianza" },
        { id: 5, label: "Vida" },
        { id: 6, label: "Ahorro" },
      ],
      catalogoEstados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California Norte" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Mexico" },
        { id: 16, estadoRepublica: "Michoacan" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo Leon" },
        { id: 20, estadoRepublica: "Oxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Queretaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosi" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz" },
        { id: 31, estadoRepublica: "Yucatan" },
        { id: 32, estadoRepublica: "Zacatecas" },
        { id: 33, estadoRepublica: "Desconocido" },
      ],
    };
  },
  watch: {
    dialog(val) {
      this.internalDialog = val;
    },
    internalDialog(val) {
      this.$emit("update:dialog", val);
    },
    errorMessage(val) {
      console.log("Error message updated:", val);
    },
  },
  methods: {
    confirmarRegistro() {
      this.$emit("confirmarRegistro");
    },

    cancelarRegistro() {
      this.$emit("update:dialog", false);
      this.$emit("cambiarStep");
    },
    setearValores() {
      this.$emit("setearValores");
    },
    obtenerLabelsPorIds(ids, catalogo) {
      if (!ids || !catalogo) return [];
      return ids.map((id) => {
        const ramoEncontrado = catalogo.find((ramo) => ramo.id === id);
        return ramoEncontrado ? ramoEncontrado.label : "Desconocido";
      });
    },
    obtenerLabelEstadoPorId(id) {
      if (!id) return "Desconocido";
      const estadoEncontrado = this.catalogoEstados.find(
        (estado) => estado.id === id
      );
      return estadoEncontrado
        ? estadoEncontrado.estadoRepublica
        : "Desconocido";
    },
  },
};
</script>
<style scoped>
.headline {
  font-weight: bold;
  margin-bottom: 0;
}

.text-center {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}
.card-dialog {
  border-radius: 16px;
  padding: 10px 0px 10px 0px;
}
.cancel-btn {
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 300;
}
.loader {
  max-width: 91px;
}
.titleKey {
  font-weight: bold;
  font-size: 14px;
  font-family: "Montserrat";
  margin-bottom: 0;
}
.value {
  font-size: 13px;
  font-family: "Montserrat";
  margin-top: 0;
  margin-bottom: 5px;
}
p {
  font-family: "Montserrat";
}
</style>
