<template>
  <v-container>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <h4>Perfil Alianza</h4>
          <span>Actualice aquí la foto y los datos de su empresa.</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="my-2 mx-3"></v-divider>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-img
            :src="
              coverImage
                ? coverImage
                : 'https://via.placeholder.com/820x312.png?text='
            "
            class="cover-image"
          ></v-img>
        </v-col>
        <v-row class="align-items-end">
          <v-col cols="auto" style="margin-top: -32px">
            <v-avatar size="100" class="ml-6" color="#00A5DF">
              <v-img
                :src="logo ? logo : 'https://via.placeholder.com/.png?text'"
                alt="Logo"
              ></v-img>
            </v-avatar>
          </v-col>
          <v-col class="align-self-end">
            <div>
              <h3>{{ nameAlliance }}</h3>
              <p>ID {{ idAliance }}</p>
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-container>

    <v-card-text>
      <v-container>
        <InputFile
          text="Logo compañia"
          typeFile="logoCompania"
          @file-loaded-base64="fileLoaded"
          @file-loaded="fileLoadedFile"
          inputId="logo-file-input"
          :size="'PNG, JPG (max. 170x170px)'"
        />
        <InputFile
          text="Imagen de fondo"
          typeFile="imagenCover"
          inputId="cover-file-input"
          @file-loaded-base64="fileLoaded"
          @file-loaded="fileLoadedFile"
          :size="'PNG, JPG (max. 820x312px)'"
        />
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <span
                class="text-subtitle-2 font-weight-bold"
                style="color: #4d5358"
                >Nombre de alianza
              </span>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Alianza"
              v-model="alianzaName"
              outlined
              dense
              primary
              color="#00a7e4"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <span
              class="text-subtitle-2 font-weight-bold"
              style="color: #4d5358"
              >Elegir color
            </span>
          </v-col>

          <v-col cols="auto">
            <div
              :style="{ backgroundColor: alianzaColor }"
              class="color-box"
            ></div>
          </v-col>

          <v-col cols="auto">
            <div @click="colorDialog = true" outlined>
              <h3 style="color: #00a5df; padding-bottom: 3px; cursor: pointer">
                Elegir color
                <v-icon right>mdi-pencil-outline</v-icon>
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-dialog v-model="colorDialog" persistent max-width="290px">
          <v-card>
            <v-card-title class="headline">Elija el color</v-card-title>
            <v-card-text>
              <v-color-picker v-model="alianzaColor" flat></v-color-picker>
            </v-card-text>

            <v-card-actions>
              <v-btn text color="primary" @click="colorDialog = false">
                Listo
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="messageDialog" max-width="290px">
          <v-card>
            <v-col align="center">
              <v-icon style="font-size: 70px; margin-bottom: 0" color="blue">
                mdi-alert-circle
              </v-icon>
            </v-col>
            <v-card-title class="headline message">{{ message }}</v-card-title>
            <v-card-actions>
              <v-col align="center">
                <v-btn color="primary" @click="messageDialog = false">
                  Entendido
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="messageDialogSuccess" max-width="fit-content">
          <v-card>
            <v-col align="center">
              <v-icon style="font-size: 70px; margin-bottom: 0" color="blue">
                mdi-check-circle
              </v-icon>
            </v-col>
            <v-card-title class="headline message pt-0">{{
              message
            }}</v-card-title>
            <v-card-actions>
              <v-col align="center">
                <v-btn color="primary" @click="goMainView"> Entendido </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-card-text>
    <v-divider class="my-2 mx-3"></v-divider>
    <v-col cols="12" style="text-align: end">
      <v-btn @click="saveAlianza" class="common-botton mr-4" dark width="200">
        Guardar
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
import { mainAxios } from "../../../../mainAxios";
import InputFile from "../../../components/InputFile.vue";
export default {
  props: {
    idAliance: Number,
    nameAlliance: String,
  },
  name: "ConfiguracionMarcaAltaAlianza",
  components: {
    InputFile,
  },
  data() {
    return {
      step: 1,
      logo: null,
      logoFile: null,
      coverImageFile: null,
      coverImage: null,
      alianzaName: this.nameAlliance,
      alianzaColor: "#D9D9D9",
      colorDialog: false,
      messageDialog: false,
      message: "",
      messageDialogSuccess: false,
    };
  },
  methods: {
    async saveAlianza() {
      // Verificar que los archivos se hayan cargado
      if (!this.logoFile || !this.coverImageFile) {
        this.messageDialog = true;
        this.message = "Debes cargar el logo y la imagen de portada.";
        return;
      }

      // Crear la instancia de FormData
      const formData = new FormData();
      formData.append("allianceId", this.idAliance);
      formData.append("mainColor", this.alianzaColor);
      formData.append("imageLogo", this.logoFile);
      formData.append("imageCover", this.coverImageFile);

      // Configuración de Axios
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      try {
        const response = await mainAxios.post(
          "v1/alliance/brand-config",
          formData,
          config
        );

        if (response.status === 200) {
          this.messageDialogSuccess = true;
          this.message = "¡La alianza fue creada con éxito!.";
          this.sendMailWelcome();
        } else {
          this.messageDialog = true;
          this.message = "Hubo un error al crear la alianza.";
        }
        localStorage.removeItem("idAliance");
        localStorage.removeItem("currentStep");
      } catch (error) {
        console.error("Error al crear la alianza:", error);
        this.messageDialog = true;
        this.message = "Hubo un error al crear la alianza.";
      }
    },
    fileLoaded(base64, type) {
      switch (type) {
        case "logoCompania":
          this.logo = base64;
          break;
        case "imagenCover":
          this.coverImage = base64;
          break;
      }
    },
    fileLoadedFile(file, type) {
      switch (type) {
        case "logoCompania":
          this.logoFile = file;
          break;
        case "imagenCover":
          this.coverImageFile = file;
          break;
      }
    },
    goMainView() {
      this.messageDialogSuccess = false;
      this.$router.push({ name: "tablaAlianzasCorporativas" });
    },
    async sendMailWelcome() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      await mainAxios.get(`/v1/alliance/${this.idAliance}`, config);
      console.log("Correo enviado");
    },
  },
};
</script>

<style scoped>
.tall-file-input {
  height: 150px !important;
}

.color-box {
  width: 24px;
  height: 24px;
  border: 1px solid #ccc;
}

.cover-image {
  height: 200px;
  background-color: #e0e0e0;
}

.v-avatar {
  border: 4px solid white;
}
span {
  color: #78909c;
  font-size: 14px;
}
.message {
  text-align: center;
}
</style>
