<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Cuerpo de correo
      </template>
      <v-expansion-panel-content>
        <div v-if="loading" style="justify-items: center;">
          <v-img
            class="responsive"
            contain
            max-height="200"
            max-width="300"
            :src="loader.createAccount"
          ></v-img>
          <div>Cargando cuerpo de correo .... </div>
        </div>
         <div v-else>
          <div v-html="htmlContent"></div>
          <div style="padding-top: 40px;">
            {{ textPlane }}
          </div>
          <div v-html="otros"></div>
        </div>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import loginData from "@/helpers/data/data-login-template.json";
export default {
  components: {
    CommonExpansionPanel,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: true,
      loader: loginData,
      htmlContent: null,
      textPlane: '',
      otros:null
    };
  },
  props: {
    datosSolicitud: Object,
  },
  mounted() {
    this.fillData();
  },
  watch:{
    datosSolicitud(){
        this.fillData()
    }
  },
  methods: {
    fillData() {
      let jsonData;
      try {
        jsonData = this.datosSolicitud.solicitudBody;
      } catch (error) {
        console.error("Error al parsear solicitudBody:", error);
        jsonData = {};
      }
      let jsonDetalle = JSON.parse(jsonData.detalle)
      console.log({jsonDetalle})
      this.htmlContent = jsonDetalle['text/html'] ? jsonDetalle['text/html'].replace(/\/r|\n/g, "") : null;
      this.textPlane = jsonDetalle['text/plain'] ?? null
      this.otros = jsonDetalle['others'] ?? null
      this.loading = false;
    },
  },
};
</script>
