<template>
  <v-row>
    <v-col cols="12">
      <common-card class="px-4 py-4">
        <v-row>
          <v-col cols="3" md="1" class="text-center">
            <img :src="dashboardJson.iconPlus" alt="" height="50" />
          </v-col>
          <v-col cols="9">
            <h2>
              Cotiza el seguro de gastos médicos
            </h2>
            <span>
              Encuentra tu seguro ideal
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <seguros-medicos-form
              :contratante="contratante"
              :generosParent="generos"
              :parentescosParent="parentescos"
              :tipoSeguro="tipoSeguro"
              @cotizar="cotizar"
              @planSeleccionado="updateAseguradoras"
              @loading="loadingShow"
            ></seguros-medicos-form>
          </v-col>
        </v-row>
      </common-card>
    </v-col>
    <v-col cols="10">
      <v-col class="pl-5" v-show="showAseguradoras">
        <span style="color: #4D5358; font-weight: 700; font-size: 32px">
          Cotiza el seguro de gastos médicos
        </span>
      </v-col>
    </v-col>
    <v-col cols="2" class="pt-8" v-show="showAseguradoras">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="cotizacionGeneral"
            item-text="text"
            item-value="value"
            :light="true"
            label="Cotización general"
            background-color="white"
            :onlyread="true"
            v-tooltip="'Texto del tooltip'"
            outlined
            v-bind="attrs"
            v-on="on"
          >
          </v-text-field>
        </template>
        <span
          >Este código te ayudará si quieres retomar el proceso de
          contratación.</span
        >
      </v-tooltip>
    </v-col>
    <v-col cols="12">
      <aseguradoras
        :primasParent="primasParent"
        :isValid="isValid"
        @createSolicitud="createSolicitud"
        v-show="showAseguradoras"
      ></aseguradoras>
    </v-col>
    <!-- <v-col cols="12">
      <v-spacer></v-spacer>
      <v-row justify="end" class="pr-2" v-show="showAseguradoras">
        <div class="d-flex flex-wrap align-center mt-4">
          <div class="ml-2 d-flex flex-column justify-center align-center">
            <v-icon color="#00A7E4">mdi-content-save</v-icon>
            <v-btn text color="#00A7E4">
              Guardar
            </v-btn>
          </div>

          <div class="ml-2 d-flex flex-column justify-center align-center">
            <v-icon color="#00A7E4">mdi-cloud-download</v-icon>
            <v-btn text color="#00A7E4">
              Descargar
            </v-btn>
          </div>

          <v-btn
            depressed
            dark
            color="#00A7E4"
            class="ml-2 text-center"
            rounded
          >
            Enviar comparativa
            <v-icon class="ml-2">mdi-send</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-col> -->
    <commond-loading
      :show="loading"
      loadingText="Cargando..."
      :loadingImage="loadingGifs.iconPlus"
    />
    <CommonSolicitudCreadaModal :dialog="dialog" @userChoice="changeView" />
    <CommonActionModal
      :dialog="modalSinVigencia"
      :title="titleModal"
      :subtitle="subtitle"
      :bottonText="bottonText"
      @userChoice="choiceInvalidDate"
    />
  </v-row>
</template>
<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
import Aseguradoras from "./Aseguradoras.vue";
import SegurosMedicosForm from "./SegurosMedicosForm.vue";
import dashboardJson from "../../../../helpers/data/data-dashboard-template.json";
import loadings from "../../../../helpers/data/data-loading-gif.json";
import CommondLoading from "../../../../components/commonComponents/CommondLoading.vue";
import { mainAxios } from "../../../../mainAxios";
import moment from "moment";
import RfcFacil from "rfc-facil";
import CommonSolicitudCreadaModal from "@/components/commonComponents/CommonSolicitudCreadaModal.vue";
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";


export default {
  name: "CotizacionSeguroMedico",
  components: {
    CommonCard,
    SegurosMedicosForm,
    Aseguradoras,
    CommondLoading,
    CommonSolicitudCreadaModal,
    CommonActionModal
  },
  async created() {
    this.obtenerProductos()
    this.loading = true;
    if (!sessionStorage.interToken) {
      await this.initLoginInter();
    }
    await this.getGeneros();
    await this.getParentescos();
    await this.getAseguradoras();
    if (sessionStorage.getItem("medicoRequest")) {
      this.contratante = JSON.parse(sessionStorage.getItem("medicoRequest"))
      this.isRecotizacion = this.contratante.recotizacion
      this.cotizacionGeneral = this.contratante.cotizacionGeneral
      this.checkVigencia()
      delete this.contratante.recotizacion
      delete this.contratante.cotizacionGeneral
      delete this.contratante.fecha_cotizacion
      //sessionStorage.removeItem("medicoRequest");
    }
    if (sessionStorage.getItem("primasMedico")) {
        this.primasParent =JSON.parse(sessionStorage.getItem("primasMedico"))
        this.showAseguradoras = true
    }
    this.loading = false;
    this.createCotizacionGeneral()
  },
  data() {
    return {
      loading: false,
      selectedDate: null,
      aseguradoras: [],
      dashboardJson: dashboardJson,
      loadingGifs: loadings,
      showAseguradoras: false,
      contratante: {
        id: "",
        nombre: "",
        aPaterno: "",
        aMaterno: "",
        asegurados: [],
        cp: "",
        correo: "",
        fechanacimiento: "",
        genero: "",
        telefono: "",
        tipoContratante: "individual",
        ultimoPaso: 0,
        agente:"",
        alianzaId:null
      },
      tipoSeguro: "Esencial",
      generos: [],
      parentescos: [],
      tipoContratante: "",
      primas: [
        {
          nameId: "generaldeseguros",
          sumaAsegurada: "$3,000,000",
          deducible: "Sin deducible",
          coaseguro: "10%",
          topeCoaseguro: "$20,000",
          beneficios: "5 Incluidos",
          total: 0,
          show: true,
          idAseguradora:33
        },
        {
          nameId: "gnp",
          sumaAsegurada: "$7,365,000",
          deducible: "$19,500",
          coaseguro: "10% por padecimiento",
          topeCoaseguro: "$75,500",
          beneficios: "3 Incluidos",
          total: 0,
          show: true,
          idAseguradora:3
        },
        {
          nameId: "axa",
          sumaAsegurada: "$6,600,000",
          deducible: "$21,000",
          coaseguro: "10% por padecimiento",
          topeCoaseguro: "$45,000",
          beneficios: "2 Incluidos",
          total: 0,
          show: true,
          idAseguradora:11
        },
        {
          nameId: "mapfre",
          sumaAsegurada: "$6,000,000",
          deducible: "$19,000",
          coaseguro: "10% por padecimiento",
          topeCoaseguro: "$42,000",
          beneficios: "No Incluidos",
          total: 0,
          show: true,
          idAseguradora:27
        },
      ],
      primasParent: [],
      agenteId: null,
      estado_id:null,
      ciudad:null,
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      dialog:false,
      solicitudCreada:{},
      cotizacionGeneral:'',
      isRecotizacion:false,
      modalSinVigencia:false,
      titleModal: "",
      subtitle:'',
      bottonText: "",
      isValid: true,
      productos:[],
      alianzaId:null
    };
  },
  watch:{
    'contratante.cp'(val){
      console.log("aqui mero ", val)
      window.dataLayer.push({
        event: "ITFY_gmm_cotizar",
        fecha_nacimiento: this.contratante.fechanacimiento.split("-").reverse().join("/"),
        sexo: this.contratante.genero,
        tipo_seguro: this._data.contratante.tipoContratante,
        plan: this.tipoSeguro,
        boton_contacto: "Cotizar",
        userID: localStorage.getItem("agenteMail"),
        correo: this.contratante.correo,
        telefono: this.contratante.telefono,
      })
      if (typeof val != "undefined") {
        if (val.length == 5) {
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          mainAxios
            .get("/v1/catalogo/direccion?cp=" + val, config)
            .then((response) => {
              if (response.data.listaColonias.length >= 1) {
                this.estado_id = Number(response.data.nEstado);
                this.ciudad = response.data.nombreMunicipio;
              }
            });
        }
      }
    }
  },
  methods: {
    loadingShow(show) {
      this.loading = show;
    },
    async initLoginInter() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      const response = await mainAxios.get("/v1/seguro-medico/init", config);
      sessionStorage.interToken = response.data.data.accessToken;
      this.loading = false;
      return true;
    },
    async getParentescos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": "" + sessionStorage.interToken,
        },
      };
      const resp = await mainAxios.get("/v1/seguro-medico/parentesco", config);
      this.parentescos = resp.data.data.parentescos;
    },
    async getGeneros() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": "" + sessionStorage.interToken,
        },
      };
      const resp = await mainAxios.get("/v1/seguro-medico/genero", config);
      this.generos = resp.data.data.generos;
    },
    async getAseguradoras() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": "" + sessionStorage.interToken,
        },
      };
      const resp = await mainAxios.get(
        "/v1/seguro-medico/paquetes/" + this.tipoSeguro,
        config
      );
      this.aseguradoras = resp.data.data.paquetes;
    },
    async cotizar(contratanteUpdated) {
      try {
        this.isValid = true;
        this.isRecotizacion = false;
        this.createCotizacionGeneral()
        this.agenteId = contratanteUpdated.agente;
        this.alianzaId = contratanteUpdated.alianzaId;
        this.loading = true;
        delete contratanteUpdated.agente
        delete contratanteUpdated.alianzaId
        this.contratante = { ...contratanteUpdated };
        this.tipoContratante = contratanteUpdated.tipoContratante + "";
        if (this.contratante.id.length > 0) {
          // ya esta registrado y actualizamos
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
              "inter-token": "" + sessionStorage.interToken,
            },
          };
          const payload = { ...this.contratante };
          delete payload.tipoContratante;
          delete payload.asegurados;
          console.log(this.contratante.tipoContratante);
          const resp = await mainAxios.post(
            "/v1/seguro-medico/update-contratante",
            payload,
            config
          );
          if (resp.data.data.contratante) {
            this.contratante = {
              ...resp.data.data.contratante,
              tipoContratante: this.contratante.tipoContratante,
            };
          }
        } else {
          // registro nuevo
          if (this.contratante.tipoContratante === "individual") {
            this.contratante.asegurados = [];
            this.contratante.asegurados.push({
              cp: this.contratante.cp,
              fechanacimiento: this.contratante.fechanacimiento,
              nombre:
                this.contratante.nombre +
                " " +
                this.contratante.aPaterno +
                " " +
                (this.contratante.aMaterno !== ""
                  ? this.contratante.aMaterno
                  : ""),
              genero: this.contratante.genero,
              indice: 0,
              parentesco: "Titular",
              titular: true,
            });
          }
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
              "inter-token": "" + sessionStorage.interToken,
            },
          };
          const payload = { ...this.contratante };
          console.log(this.contratante.tipoContratante);
          const resp = await mainAxios.post(
            "/v1/seguro-medico/contratante",
            payload,
            config
          );
          if (resp.data.data.contratante) {
            this.contratante = {
              ...resp.data.data.contratante,
              tipoContratante: this.contratante.tipoContratante,
            };
          }
          console.log(this.contratante.tipoContratante);
        }
        if (this.contratante !== undefined) {
          this.contratante.tipoContratante = this.tipoContratante;
          this.primasParent = [];
          for (let i = 0; i < this.aseguradoras.length; i++) {
            let nombreId = this.aseguradoras[i].nombreAseguradora
              .toLowerCase()
              .replace(/\s+/g, "");
            const indexAse = this.primas.findIndex(
              (item) => item.nameId === nombreId
            );
            try {
              const cotizacionX = await this.cotizarAseguradora(
                this.aseguradoras[i].id,
                this.aseguradoras[i].paquetes[0].id,
                this.contratante.folio,
                this.contratante.id,
                this.agenteId,
                this.alianzaId
              );
              this.primas[indexAse].total = cotizacionX.total;
            } catch (err) {
              this.primas[indexAse].total = 0;
            }
          }
          this.primasParent = this.primas.filter(
            (elemento) => elemento.total > 0
          );
          this.showAseguradoras = true;
        }
        this.contratante.agente = this.agenteId
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.message);
        }
        console.log(error);
      }
      this.loading = false;
      Object.assign(this.contratante, { 'agente': this.agenteId });
    },
    async cotizarAseguradora(
      aseguradoraId,
      paqueteId,
      contratanteFolio,
      contratanteId,
      agenteId,
      alianzaId
    ) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": "" + sessionStorage.interToken,
        },
      };
      const payloadCotizacion = {
        folio: contratanteFolio,
        aseguradoraId,
        paqueteId,
        contratanteId,
        agenteId: agenteId,
        cotizacion_general: this.cotizacionGeneral,
        plan: this.tipoSeguro,
        alianzaId: alianzaId
      };
      const resp = await mainAxios.post(
        "/v1/seguro-medico/cotizacion",
        payloadCotizacion,
        config
      );
      return resp.data.data;
    },
    async updateAseguradoras(planSeleccionado, contratanteNew) {
      this.loading = true;
      this.tipoSeguro = planSeleccionado;
      this.contratante = { ...contratanteNew };
      await this.getAseguradoras();
      if (this.contratante.id) {
        for (let i = 0; i < this.aseguradoras.length; i++) {
          let nombreId = this.aseguradoras[i].nombreAseguradora
            .toLowerCase()
            .replace(/\s+/g, "");
          const indexAse = this.primas.findIndex(
            (item) => item.nameId === nombreId
          );
          try {
            const cotizacionX = await this.cotizarAseguradora(
              this.aseguradoras[i].id,
              this.aseguradoras[i].paquetes[0].id,
              this.contratante.folio,
              this.contratante.id
            );
            this.primas[indexAse].total = cotizacionX.total;
          } catch (err) {
            this.primas[indexAse].total = 0;
          }
        }
      }
      this.loading = false;
    },
    createSolicitud(indexAseguradora) {
      window.dataLayer.push({
            event: "ITFY_gmm_seleccionar",
            plan: this.tipoSeguro,
            tipo_seguro: this.tipoContratante,
            aseguradora: this.primasParent[indexAseguradora].nameId,
            total_pago: this.primasParent[indexAseguradora].total,
            userID: localStorage.getItem('agenteMail'),
            boton_contacto: 'Lo quiero',
            no_cotizacion: this._data.cotizacionGeneral,
          })
      this.loading = true
      const request = {
        //Datos generales de la solicitud
        agente: parseInt(this.agenteId),
        agenteId: parseInt(this.agenteId),
        idOrigenLead: 1,
        inicio: moment().format("YYYY-MM-DD"),
        fin: moment()
          .add(1, "year")
          .format("YYYY-MM-DD"),
        anios: "1",
        fechaPrimerPago: moment().format("YYYY-MM-DD"),
        campania: "Portal Asesores Digitales Salud",
        campania_id: 63,
        categoriaSelected: "SALUD",
        tipoSelected: "Emisión",
        detalle: "Cotización realizada mediante el portal de Interify",
        //Fin datos generales
        //Datos del cliente
        nombres: this.contratante.nombre,
        apellidoPaterno: this.contratante.aPaterno,
        apellidoMaterno: this.contratante.aMaterno,
        fechaNacimiento: this.contratante.fechanacimiento,
        edad: moment().diff(
          moment(this.contratante.fechanacimiento, "YYYY-MM-DD"),
          "years"
        ),
        genero: this.contratante.genero,
        correoElectronico: this.contratante.correo,
        celular: this.contratante.telefono,
        estadoCivilSelected: "Soltero(a)",
        rfc: this.getRfc(),
        codigoPostal: this.contratante.cp,
        estadoRepublicaSelected: this.getEstadoName(this.estado_id),
        municipio: this.ciudad,
        calle: "NA",
        numExterior: "NA",
        numInterior: "NA",
        colonia: "NA",
        nombre_cliente: "",
        regimen_persona: "Física",
        //fin datos del cliente
        //Keys que manejan estatus o datos menores que no tenemos
        origenSolicitudSelected: 'Portal',
        comercial_asignado: null,
        actividadSolicitud: null,
        archivos: null,
        razon_social: null,
        numeroPoliza: null,
        solicitudCompletaSelected: false,
        checkPendienteInfo: false,
        checkCancelacion: false,
        causaPendienteInformacion: null,
        dateFormattedInicioVigencia: null,
        menufechasParaCotizar: false,
        polizaRenovar: null,
        fechaInicioVigenciaRenovar: null,
        fechaFinVigenciaRenovar: null,
        operadorUuid: null,
        //Fin Keys que manejan estatus o datos menores que no tenemos

        //Datos por definir para prod
        producto_id: this.obtenerProductoParaSolicitud(this.primas[indexAseguradora].idAseguradora),
        aseguradora_id: this.primas[indexAseguradora].idAseguradora,
        productoId: this.obtenerProductoParaSolicitud(this.primas[indexAseguradora].idAseguradora),
        //fin datos por defenir

        //inicio datos por ramo
        inicioVigencia: moment().format("YYYY-MM-DD"),
        finVigencia: moment()
          .add(1, "year")
          .format("YYYY-MM-DD"),
        periodicidadPago: 1,
        formaPagoSelected: "Tarjeta de Crédito",

        sumaAsegurada: this.formatToNumber(
          this.primasParent[indexAseguradora].sumaAsegurada
        ),
        deducible: this.formatToNumber(
          this.primasParent[indexAseguradora].deducible
        )
          ? this.formatToNumber(this.primasParent[indexAseguradora].deducible)
          : "0",
        coaseguro: this.formatToNumber(
          this.primasParent[indexAseguradora].coaseguro
        ),

        nivelHospitalario: "Alto",
        ceroDeducible: "No ampara",
        medicinaFueraHospital: "No ampara",
        emergenciaExtranjero: "No ampara",
        enfermedadesCatastroficas: "No ampara",
        coberturaExtranjero: "No ampara",
        preexistencias: "No ampara",
        proteccionDental: "No ampara",
        maternidadExtendida: "No ampara",
        coberturaNacional: "No ampara",
        reconocimientoAntiguedad: "No ampara",
        dependientes: "NA",
        fechasParaCotizar: "NA",
        procesoCotizacionSelected: "Cotización",
        alianzaId:this.alianzaId
      };
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.post("v1/solicitud/insert/", request, config)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false
            this.solicitudCreada = response.data
            this.dialog = true
          }
        })
        .catch((error) => {
          this.loading = false
          console.log(error);
        });
    },
    formatToNumber(num) {
      return parseFloat(num.replace(/[^0-9.]/g, ""));
    },
    getRfc() {
      return RfcFacil.forNaturalPerson({
        name: this.contratante.nombre,
        firstLastName: this.contratante.aPaterno,
        secondLastName: this.contratante.aMaterno,
        day: this.contratante.fechanacimiento.split("-")[2],
        month: this.contratante.fechanacimiento.split("-")[1],
        year: this.contratante.fechanacimiento.split("-")[0],
      });
    },
    getEstadoName(id){
      var estado = this.estados.find((e) => e.id == id)
      return estado ? estado.name : 'No existe'
    },
    changeView(val) {
      if (val)
        this.$router.push("/detalles-solicitud/" + this.solicitudCreada.id);
      else location.reload();
    },
    createCotizacionGeneral() {
      if (!this.isRecotizacion) {
        //const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result1 = "";
        const charactersLength = characters.length;
        for (let i = 0; i < 10; i++) {
          result1 += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        this.cotizacionGeneral = result1;
        return this.cotizacionGeneral;
      } else return this.cotizacionGeneral;
    },
    checkVigencia() {
      var jsonRecotizacion = JSON.parse(sessionStorage.getItem("medicoRequest"));

      if (jsonRecotizacion && jsonRecotizacion.fecha_cotizacion) {
        var parsedDate = moment(jsonRecotizacion.fecha_cotizacion);
        console.log("parsedDate", parsedDate);

        var diff = moment().diff(parsedDate, "days");
        console.log("diff", diff);

        if (diff > 7) {
          this.modalSinVigencia = true;
          this.titleModal = "Vigencia vencida";
          this.subtitle = "¡Las cotizaciones de Interify tienen vigencia de 7 días, pero no te preocupes puedes volver a cotizar con los mismos datos!";
          this.bottonText = "Recotizar";
          this.isValid = false;
        }
      } 
    },
    choiceInvalidDate(val){
      if(val){
        this.cotizar(this.contratante)
      }
      this.modalSinVigencia = false
    },
    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get('/v1/producto/list', config).then(response => {
        response.data.map(element => {
          if(element.ramo == 'SALUD' && element.canal == 'MASIVOS')
          this.productos.push({
            nombre: `${element.ramo}`+ ' / ' + `${element.nombre}` + ' / ' + `${element.nombreAseguradora}`,
            id: element.id,
            ramo: element.ramo,
            aseguradoraId: element.aseguradoraId
          })
        })
      });
    },
    obtenerProductoParaSolicitud(aseguradoraId){
      return this.productos.find((e)=> e.aseguradoraId == aseguradoraId).id 
    }
  },
  mounted() {
    window.dataLayer.push({
      event: 'ITFY_gmm_cotizar_v',
      no_cotizacion: undefined,
      userID: localStorage.agenteMail,
      correo: undefined,
      telefono: undefined,
    });
  }

};
</script>
<style></style>
