<template>
  <v-row id="topEmitir">
    <v-col cols="12" sm="6">
      <common-card
        class="py-4"
        style="border: solid 1px #6DB7CD;min-height: 15rem"
      >
        <v-row class="ml-4 mr-4 pb-0">
          <v-col cols="5" class="pb-0">
            <span
              class="text-h5 font-weight-bold"
              style="color:#4D5358!important"
            >
              Conductor
            </span>
          </v-col>
          <v-col>
            <v-btn
              v-show="!showEditConductor"
              small
              text
              @click="editConductor()"
              v-if="isValid"
            >
              <v-icon color="#00A7E4">mdi-pencil-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              v-show="showEditConductor"
              color="#00A7E4"
              dark
              class="rounded-xl"
              small
              @click="saveConductor()"
              :disabled="!validEditConductor()"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-show="!showEditConductor" class="mx-4">
          <v-col cols="4">
            <h4>Nombre</h4>
            <span>{{ carPrice.personalData.nombre }}</span>
          </v-col>
          <v-col cols="4">
            <h4>Apellido P</h4>
            <span>{{ carPrice.personalData.apellidoPaterno }}</span>
          </v-col>
          <v-col cols="4">
            <h4>Apellido M</h4>
            <span>{{ carPrice.personalData.apellidoMaterno }}</span>
          </v-col>
          <v-col cols="4">
            <h4>C.P.</h4>
            <span>{{ carPrice.personalData.cp }}</span>
          </v-col>
          <v-col cols="4">
            <h4>Género</h4>
            <span>{{ carPrice.personalData.genero }}</span>
          </v-col>
          <v-col cols="4">
            <h4>Edad</h4>
            <span>{{
              calculateAge(carPrice.personalData.fechaDeNacimiento)
            }}</span>
          </v-col>
        </v-row>
        <v-row v-show="showEditConductor" class="mx-4">
          <v-col cols="12" md="4">
            <v-text-field
              v-model.trim="nombreU"
              label="Nombre"
              hide-details="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="primerApellidoU"
              label="Apellido P"
              hide-details="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="segundoApellidoU"
              label="Apellido M"
              hide-details="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="codigoPostalU"
              label="Código Postal"
              hide-details="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-label>Género*</v-label>
            <v-radio-group v-model="generoU" row hide-details="true">
              <v-radio label="M" value="M" color="#00A7E4"></v-radio>
              <v-radio label="F" value="F" color="#00A7E4"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              type="date"
              v-model="fechanNacimientoU"
              label="Edad"
              hide-details="true"
            ></v-text-field>
          </v-col>
        </v-row>
      </common-card>
    </v-col>
    <v-col cols="12" sm="6">
      <common-card
        class="py-4"
        style="border: solid 1px #6DB7CD;min-height: 15rem"
      >
        <v-row class="ml-4 mr-4 pb-0">
          <v-col cols="5" class="pb-0">
            <span
              class="text-h5 font-weight-bold"
              style="color:#4D5358!important"
            >
              Vehículo
            </span>
            <h4 v-show="!showEditAuto" class="mt-3">Datos del vehículo</h4>
          </v-col>
          <v-col>
            <v-btn
              v-show="!showEditAuto"
              v-if="isValid"
              small
              text
              @click="editAuto()"
            >
              <v-icon color="#00A7E4">mdi-pencil-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              v-show="showEditAuto"
              color="#00A7E4"
              dark
              class="rounded-xl"
              small
              @click="saveAuto()"
              :disabled="!validEditCarro()"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-show="!showEditAuto" class="ml-4 mr-4 pb-0">
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <span>{{ carPrice.carBrand.brand }}</span
                ><br />
                <span>{{ carPrice.carBrand.model.description }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <span>{{ carPrice.carBrand.year }}</span>
          </v-col>
        </v-row>
        <v-row v-show="showEditAuto" class="ml-4 mr-4 pb-0">
          <v-col md="6" cols="12" class="pb-0">
            <v-autocomplete
              :items="brand"
              :item-text="(item) => item"
              :item-value="(item) => item"
              label="Marca*"
              outlined
              v-model="marca"
              class="v-autocomplete--small"
              hide-details="true"
              @change="updateVersions()"
            ></v-autocomplete>
          </v-col>
          <v-col md="6" cols="12" class="pb-0">
            <v-autocomplete
              v-model="axo"
              :items="years"
              :item-text="(item) => item"
              :item-value="(item) => item"
              label="Año*"
              outlined
              class="v-autocomplete--small"
              hide-details="true"
              @change="getVersions(marca, axo)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-autocomplete
              v-model="modelo"
              :items="versions"
              item-text="description"
              :item-value="(item) => item"
              label="Modelo*"
              outlined
              class="v-autocomplete--small"
              hide-details="true"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </common-card>
    </v-col>
    <v-col cols="12" class="d-none d-sm-block">
      <common-card class="py-4">
        <v-row class="mx-4">
          <v-col cols="6">
            <v-label
              style="color:#4D5358!important"
              class="text-h6 font-weight-bold pb-2"
              >Modalidad de pago</v-label
            >
            <v-select
              class="pt-2"
              outlined
              v-model="pagoSelect"
              :items="pagos"
              label="Selecciona un pago"
              item-text="nombre"
              item-value="inter"
              :disabled="!isValid"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-label
              style="color:#4D5358!important"
              class="text-h6 font-weight-bold pb-2"
              >Tipo de plan</v-label
            >
            <v-select
              class="pt-2"
              outlined
              v-model="planSeguro"
              :items="tiposPlan"
              label="Selecciona un tipo de plan"
              item-text="nombre"
              item-value="id"
              :disabled="!isValid"
            ></v-select>
          </v-col>
          <!-- <v-col cols="12" class="pb-0 pt-0">
            <v-label
              style="color:#4D5358!important"
              class="text-h6 font-weight-bold pb-2"
              >Coberturas</v-label
            >
          </v-col>
          <v-col md="3" sm="12">
            <v-select label="Daños materiales" class="pt-2" outlined></v-select>
          </v-col>
          <v-col md="3" sm="12">
            <v-select label="Robo total" class="pt-2" outlined></v-select>
          </v-col>
          <v-col md="3" sm="12">
            <v-select
              label="Responsabilidad Civil"
              class="pt-2"
              outlined
            ></v-select>
          </v-col>
          <v-col md="3" sm="12">
            <v-select
              label="Gastos Médicos Ocupantes"
              class="pt-2"
              outlined
            ></v-select>
          </v-col> -->
          <v-col cols="12" class="text-right">
            <v-btn
              @click="recotizacionModal = true"
              depressed
              color="#00A7E4"
              rounded
              dark
              :disabled="checkIfRecotizacion"
              >Volver a cotizar</v-btn
            >
          </v-col>
        </v-row>
      </common-card>
    </v-col>
    <v-col cols="12">
      <common-card class="py-4">
        <v-row class="ml-4 mr-4">
          <v-col cols="12">
            <h2 id="aseguradorasX">
              Escoge la aseguradora de preferencia o envia una comparativa.
            </h2>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="selectedPrice"
              :items="pricesOrder"
              item-text="text"
              item-value="value"
              label="Buscar por precio"
              @change="filterByPrice"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="cotizacionGeneral"
                  item-text="text"
                  item-value="value"
                  label="Cotización general"
                  :onlyread="true"
                  v-tooltip="'Texto del tooltip'"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <span
                >Este código te ayudará si quieres retomar el proceso de
                contratación.</span
              >
            </v-tooltip>
          </v-col>
          <v-col cols="12">
            <common-lista-aseguradoras
              :items="filteredAseguradoras"
              :tipoCotizacion="'Coche'"
              :cotizacionGeneral="cotizacionGeneral"
              :isValid="isValid"
              :tipo-vehiculo="this.modelo.type"
              nameFunction="goConfirmation"
              @goConfirmation="goConfirmation"
              @recotizar="againCotizar"
            />
            <v-row v-show="unaCotizacion">
              <v-col cols="12">
                <h2 class="pa-4 text-center">
                  Las aseguradoras seleccionadas no cuentan con cobertura para
                  el vehículo seleccionado
                </h2>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                  style="widht: 100%; padding: 6px!important"
                  color="#00A7E4"
                  block
                  dark
                  class="rounded-xl"
                  @click="goAseguradoras()"
                >
                  Elegir aseguradoras
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center flex-column align-center"
            v-if="isFetching"
          >
            <v-col class="text-center">
              <v-progress-circular
                color="#00A7E4"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col class="text-center">
              <h3>Cargando...</h3>
            </v-col>
          </v-col>
        </v-row>
      </common-card>
    </v-col>
    <v-row v-if="isValid && aseguradoras.length > 0 && !isFetching">
      <v-col cols="12" class="d-flex justify-center justify-md-end p-0 mt-3">
        <!-- <v-col cols="3" md="2" lg="1">
          <v-btn block dark class="rounded-xl" color="transparent" icon>
            <v-row>
              <v-col class="mb-20" cols="12">
                <v-icon
                  class="d-inline d-md-none"
                  color="#029ECF"
                  left
                  size="x-small"
                >
                  mdi-content-save
                </v-icon>
                <v-icon class="d-none d-md-inline" color="#029ECF" left>
                  mdi-content-save
                </v-icon>
              </v-col>
              <v-col class="text-center" cols="12">
                <span
                  class="d-inline d-md-none"
                  style="color: #029ECF!important; font-size: 0.6em;"
                  >Guardar</span
                >
                <span
                  class="d-none d-md-inline"
                  style="color: #029ECF!important"
                  >Guardar</span
                >
              </v-col>
            </v-row>
          </v-btn>
        </v-col> -->
        <v-col cols="3" md="2" lg="1" @click="sendCotizacion(false)">
          <v-btn block dark class="rounded-xl" color="transparent" icon>
            <v-row>
              <v-col class="mb-20" cols="12">
                <v-icon
                  class="d-inline d-md-none"
                  color="#029ECF"
                  left
                  size="x-small"
                >
                  mdi-cloud-download
                </v-icon>
                <v-icon class="d-none d-md-inline" color="#029ECF" left>
                  mdi-cloud-download
                </v-icon>
              </v-col>
              <v-col class="text-center" cols="12">
                <span
                  class="d-inline d-md-none"
                  style="color: #029ECF!important; font-size: 0.6em;"
                  >Descargar</span
                >
                <span
                  class="d-none d-md-inline"
                  style="color: #029ECF!important"
                  >Descargar</span
                >
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
        <v-col cols="6" md="3" lg="3" xl="2">
          <v-btn
            style="widht: 100%; padding: 6px!important"
            color="#00A7E4"
            block
            dark
            class="rounded-xl"
            @click="sendCotizacion(true)"
            :disabled="!isValid"
          >
            <span class="d-inline d-md-none" style="font-size: 0.5em;">
              Envíar cotización</span
            >
            <span class="d-none d-md-inline"> Envíar cotización</span>
            <v-icon class="d-inline d-md-none ml-1 " left size="x-small">
              mdi-send
            </v-icon>
            <v-icon class="d-none d-md-inline ml-2 mb-9" left>
              mdi-send
            </v-icon>
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
    <commond-loading
      :show="loading"
      :loadingImage="dataImage.iconCar"
      loadingText="Cargando..."
    />
    <common-recotizacion-modal
      :dialog="recotizacionModal"
      @userChoice="againCotizar"
    >
    </common-recotizacion-modal>
    <CommonActionModal
      :dialog="modalSinVigencia"
      :title="titleModal"
      :subtitle="subtitle"
      :bottonText="bottonText"
      @userChoice="choiceInvalidDate"
    />
  </v-row>
</template>

<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
import CommonListaAseguradoras from "../../../../components/commonComponents/CommonListaAseguradoras.vue";
import { mainAxios } from "@/mainAxios.js";
import imageAseguradoras from "../../../../helpers/data/data-image-aseguradoras.json";
import CommondLoading from "../../../../components/commonComponents/CommondLoading.vue";
import dataVehiculo from "../../../../helpers/data/data-loading-gif.json";
import CommonRecotizacionModal from "@/components/commonComponents/CommonRecotizacionModal.vue";
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";
import moment from "moment";

export default {
  name: "EmitirVehiculo",
  components: {
    CommonCard,
    CommonListaAseguradoras,
    CommondLoading,
    CommonRecotizacionModal,
    CommonActionModal,
  },
  data() {
    return {
      isFetching: false,
      aseguradoras: [],
      carPrice: {},
      loading: false,
      cotizacion: [],
      images: imageAseguradoras,
      dataImage: dataVehiculo,
      selectedPrice: null,
      prices: [{ text: "Todos", value: null }],
      filteredAseguradoras: [],
      showEditConductor: false,
      showEditAuto: false,
      brand: [],
      versions: [],
      years: this.generateLast60Years(),
      token: "",
      marca: "",
      modelo: {},
      axo: 0,
      nombreU: "",
      primerApellidoU: "",
      segundoApellidoU: "",
      codigoPostalU: "",
      generoU: "",
      fechanNacimientoU: "",
      pagos: [],
      pagoSelect: "A",
      tiposPlan: [
        {
          id: "AMP",
          nombre: "Amplia",
        },
        {
          id: "RC",
          nombre: "Responsabilidad Civil",
        },
        {
          id: "LIMIT",
          nombre: "Limitada",
        },
      ],
      planSeguro: "AMP",
      unaCotizacion: false,
      cotizacionGeneral: "",
      isRecotizacion: false,
      recotizacionModal: false,
      modalSinVigencia: false,
      titleModal: "",
      subtitle: "",
      bottonText: "",
      isValid: true,
      agentes:[]
    };
  },
  methods: {
    goAseguradoras() {
      this.$router.push("/cotizacion/vehiculos");
    },
    againCotizar(val) {
      if (val) {
        this.carPrice.plan = this.planSeguro;
        this.carPrice.tipoPago = this.pagoSelect;
        this.aseguradoras = [];
        this.filteredAseguradoras = [];
        this.isRecotizacion = false;
        this.isValid = true;
        this.createPrice();
      }
      this.recotizacionModal = false;
    },
    validEditConductor() {
      return [
        this.nombreU,
        this.primerApellidoU,
        this.codigoPostalU,
        this.generoU,
        this.fechanNacimientoU,
      ].every((field) => !!field);
    },
    validEditCarro() {
      return [this.marca, this.modelo.vehicle_id, this.axo].every(
        (field) => !!field
      );
    },
    async updateVersions() {
      if (this.axo > 0) {
        this.loading = true;
        await this.getVersions(this.marca, this.axo);
        this.modelo = {};
        this.loading = false;
      }
    },
    async getVersions(brand, year) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": this.token,
        },
      };
      mainAxios
        .get(
          `/v1/cotizacion-ws/auto/catalogo/versiones/${brand}/${year}`,
          config
        )

        .then(({ data: { data } }) => {
          this.versions = data.versiones;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getBrands() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": this.token,
        },
      };
      mainAxios
        .get("/v1/cotizacion-ws/auto/catalogo/marcas", config)
        .then(({ data: { data } }) => {
          this.brand = data.marcas;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getPagos() {
      try {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        const resp = await mainAxios.get(
          "/v1/cotizacion-ws/auto/pagos",
          config
        );
        this.pagos = resp.data.data;
        sessionStorage.setItem("datosPago", JSON.stringify(this.pagos));
      } catch (error) {
        console.log(error);
      }
    },
    generateLast60Years() {
      const currentYear = new Date().getFullYear();
      let years = [];
      for (let i = 0; i <= 60; i++) {
        years.push(currentYear - i);
      }
      return years;
    },
    editAuto() {
      this.showEditAuto = true;
    },
    async saveAuto() {
      this.showEditAuto = false;
      this.loading = true;
      this.carPrice.carBrand.year = this.axo;
      this.carPrice.carBrand.brand = this.marca;
      this.carPrice.carBrand.model = this.modelo;
      this.aseguradoras = [];
      this.filteredAseguradoras = [];
      await this.createPrice();
      this.loading = false;
    },
    editConductor() {
      this.showEditConductor = true;
    },
    async saveConductor() {
      this.showEditConductor = false;
      this.loading = true;
      this.aseguradoras = [];
      this.filteredAseguradoras = [];
      this.carPrice.personalData.nombre = this.nombreU;
      this.carPrice.personalData.apellidoPaterno = this.primerApellidoU;
      this.carPrice.personalData.apellidoMaterno = this.segundoApellidoU;
      this.carPrice.personalData.cp = this.codigoPostalU;
      this.carPrice.personalData.genero = this.generoU;
      this.carPrice.personalData.fechaDeNacimiento = this.fechanNacimientoU;
      await this.createPrice();
    },
    async createPrice() {
      this.$router.push({ hash: "#topEmitir" });
      this.isFetching = true;
      this.unaCotizacion = false;
      this.prices = [{ text: "Todos", value: null }];
      sessionStorage.setItem("carPrice", JSON.stringify(this.carPrice));
      this.createCotizacionGeneral();
      const data = {
        usuarioJson: {
          birthDate: this.carPrice.personalData.fechaDeNacimiento,
          email: this.carPrice.personalData.correo,
          gender: this.carPrice.personalData.genero,
          phone: this.carPrice.personalData.telefono,
          name: this.carPrice.personalData.nombre,
          lastname: this.carPrice.personalData.apellidoPaterno,
          secondLastname: this.carPrice.personalData.apellidoMaterno,
          cp: this.carPrice.personalData.cp,
        },
        plan: this.carPrice.plan,
        tipoPago: this.carPrice.tipoPago,
        trackingId: this.carPrice.trackingId,
        vehicleId: this.carPrice.carBrand.model.vehicle_id,
        vehicleType:this.carPrice.carBrand.model.type.toUpperCase(),
        custom_DM: this.carPrice.carBrand.model.type.toUpperCase() == 'MOTO' ? '10' : "5", //Daño material
        custom_RT: this.carPrice.carBrand.model.type.toUpperCase() == 'MOTO' ? '20' : "10", //Robo total
        agente_id: sessionStorage.getItem("agenteIdCotizacion"),
        alianzaId: sessionStorage.getItem('alianzaIdCotizacion'),
        cotizacion_general: this.cotizacionGeneral,
      };
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": this.carPrice.token,
        },
      };
      this.loading = true;
      if (!this.isRecotizacion) {
        for (
          let index = 0;
          index < this.carPrice.insurerSelected.length;
          index++
        ) {
          try {
            const insurer = this.carPrice.insurerSelected[index];
            const response = await mainAxios.post(
              `/v1/cotizacion-ws/auto/${insurer}/cotiza/landing`,
              data,
              config
            );
            const car = {
              carBrand: this.carBrand,
              personalData: this.personalData,
              insurerSelected: insurer,
              response: response.data.data.cotizacion,
            };
            const aseguradora = {
              id: response.data.data.cotizacion.id,
              logo: this.images[insurer],
              price: response.data.data.cotizacion.total,
              benefits: [
                "Por si ocasionas daños con tu vehículo, tienes $3,000,000",
                "Por si visitas al médico por un accidente",
                "Por si necesitas ayuda con tu vehículo o apoyo legal",
              ],
              coverages: response.data.data.cotizacion.coverages,
              insurer,
              plan: this.carPrice.plan,
              tipoPago: this.carPrice.tipoPago,
              cotizacion_general: this.cotizacionGeneral,
              agenteId: response.data.data.cotizacion.agenteId,
              fecha: moment(response.data.data.cotizacion.fecha, 'YYYY/MM/DD hh:mm:ss').format('DD/MM/YYYY hh:mm:ss'),
              nombreCliente: response.data.data.nombre,
            };
            this.cotizacion.push(car);
            this.aseguradoras.push(aseguradora);
            this.filteredAseguradoras.push(aseguradora);
            this.filteredAseguradoras.sort(
              (a, b) => parseFloat(a.price) - parseFloat(b.price)
            );
            this.prices.push({
              text: this.formatPrice(aseguradora.price),
              value: aseguradora.price,
            });
            this.loading = false;
          } catch (error) {
            console.error("Error en la petición:", error);
          }
        }
      } else {
        this.aseguradoras = this.carPrice.aseguradoras;
        this.prices = this.carPrice.prices;
        this.filteredAseguradoras = this.carPrice.aseguradoras;
        this.filteredAseguradoras.sort(
          (a, b) => parseFloat(a.price) - parseFloat(b.price)
        );
        this.checkVigencia();
      }

      if (this.aseguradoras.length === 0) {
        this.unaCotizacion = true;
      }
      this.loading = false;
      this.$router.push({ hash: "#aseguradorasX" });
      this.isFetching = false;
    },
    calculateAge(birthDate) {
      if (!birthDate) return null;
      const today = new Date();
      const birth = new Date(birthDate);
      let age = today.getFullYear() - birth.getFullYear();
      const monthDifference = today.getMonth() - birth.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birth.getDate())
      ) {
        age--;
      }
      return age;
    },
    goConfirmacion() {},
    filterByPrice() {
      if (this.selectedPrice === null) {
        this.filteredAseguradoras = this.aseguradoras;
      } else {
        this.filteredAseguradoras = this.aseguradoras.filter(
          (aseguradora) => aseguradora.price === this.selectedPrice
        );
      }
    },
    formatPrice(price) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(price);
    },
    goConfirmation(itemSelected) {
      sessionStorage.setItem(
        "cotizacionSelected",
        JSON.stringify(itemSelected)
      );
      this.$router.push({
        name: "cotiza-vehiculos-confirmacion",
      });
    },
    createCotizacionGeneral() {
      if (!this.isRecotizacion) {
        //const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result1 = "";
        const charactersLength = characters.length;
        for (let i = 0; i < 10; i++) {
          result1 += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        this.cotizacionGeneral = result1;
      } else this.cotizacionGeneral = this.carPrice.cotizacionGeneral;
    },
    checkVigencia() {
      var parsedDate = moment(
        this.carPrice.fecha_cotizacion,
        "DD/MM/YYYY hh:mm:ss"
      );
      var diff = moment().diff(parsedDate, "days");

      if (diff > 7) {
        this.isRecotizacion = false;
        this.modalSinVigencia = true;
        (this.titleModal = "Vigencia vencida"),
          (this.subtitle =
            "¡Las cotizaciones de Interify tienen vigencia de 7 días, pero no te preocupes puedes volver a cotizar con los mismos datos!"),
          (this.bottonText = "Recotizar");
        this.isValid = false;
      }
    },
    sendCotizacion(senEmail) {
      this.infoModal()
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      var aseguradora = this.aseguradoras[0] ?? null;
      var request = {
        send_mail: senEmail,
        email: this.getAgenteName(aseguradora.agenteId),
        body: {
          send_mail: false,
          emailSegurify: true,
          first_name: this.carPrice.personalData.nombre,
          numberQuote: this.cotizacionGeneral.toString(),
          dateQuote: moment(aseguradora.fecha, 'DD/MM/YYYY hh:mm:ss').format("YYYY-MM-DD"),
          dateQuoteValid: moment(aseguradora.fecha, 'DD/MM/YYYY hh:mm:ss')
            .add(7, "days")
            .format("YYYY-MM-DD"),
          fullname: (this.carPrice.personalData.nombre ?? ' ')+' '+
                    (this.carPrice.personalData.apellidoPaterno ?? ' ') + ' ' + 
                    (this.carPrice.personalData.apellidoMaterno ?? ' ') + ' ' ,
          zipcode: this.carPrice.personalData.cp,
          birthdate: this.carPrice.personalData.fechaDeNacimiento,
          email: this.carPrice.personalData.correo,
          vehicleDescription: this.carPrice.carBrand.model.description,
          paymentPlan: this.paymentName,
          coverage: this.coverageName,
          coverages: this.coveragesToEmail,
          quotes: this.quotesToEmail,
        },
      };
      mainAxios
        .post("v1/cotizacion-ws/auto/sendgrid/cotizacion/"+this.aseguradoras[0].id, request, config)
        .then((response) => {
          if(!senEmail){
            window.open(response.data.url_cotizacion, "_blank");
          }else
            this.successModal('Correo enviado con éxito !')
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAgenteName(id){
      return this.agentes.find((e) => id == e.id).email;
    },
    async choiceInvalidDate(val) {
      if (val) {
        this.againCotizar(true);
      }
      this.modalSinVigencia = false;
    },
    successModal(text){
      this.$toast.success(text, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    infoModal(){
      this.$toast.info('Construyendo PDF cotización esperé un momento...', {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    obtenerAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: `${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`,
            id: element.id,
            uuid: element.uuid,
            email: element.username
          });
        });
      });
    },
  },
  async created() {
    this.obtenerAgentes()
    if (
      this.$route.params.carPrice &&
      typeof this.$route.params.carPrice === "object"
    ) {
      sessionStorage.setItem(
        "carPrice",
        JSON.stringify(this.$route.params.carPrice)
      );
      this.carPrice = this.$route.params.carPrice;
    } else {
      const carData = sessionStorage.getItem("carPrice");
      if (carData && carData !== "[object Object]") {
        this.carPrice = JSON.parse(carData);
      }
    }
    this.token = this.carPrice.token;
    this.axo = this.carPrice.carBrand.year;
    this.modelo = this.carPrice.carBrand.model;
    this.marca = this.carPrice.carBrand.brand;
    this.nombreU = this.carPrice.personalData.nombre;
    this.primerApellidoU = this.carPrice.personalData.apellidoPaterno;
    this.segundoApellidoU = this.carPrice.personalData.apellidoMaterno;
    this.codigoPostalU = this.carPrice.personalData.cp;
    this.generoU = this.carPrice.personalData.genero;
    this.fechanNacimientoU = this.carPrice.personalData.fechaDeNacimiento;
    this.carPrice.plan = this.carPrice.plan ? this.carPrice.plan : "AMP";
    this.carPrice.tipoPago = this.carPrice.tipoPago
      ? this.carPrice.tipoPago
      : "A";
    this.planSeguro = this.carPrice.plan;
    this.pagoSelect = this.carPrice.tipoPago;
    this.isRecotizacion = this.carPrice.recotizacion ? true : false;
    await this.getPagos();
    await this.getBrands();
    await this.getVersions(
      this.carPrice.carBrand.brand,
      this.carPrice.carBrand.year
    );
    await this.createPrice();
  },
  computed: {
    pricesOrder() {
      return this.prices.slice().sort((a, b) => a.value - b.value);
    },
    checkIfRecotizacion() {
      if (
        this.planSeguro == this.carPrice.plan &&
        this.pagoSelect == this.carPrice.tipoPago
      )
        return true;
      else return false;
    },
    paymentName() {
      return this.pagos.find((e) => this.carPrice.tipoPago == e.inter).nombre;
    },
    coverageName() {
      return this.tiposPlan.find((e) => this.carPrice.plan == e.id).nombre;
    },
    coveragesToEmail() {
      return [
      {
         "code":"DM",
         "deductive":5,
         "sum_assured":"VC"
      },
      {
         "code":"RT",
         "deductive":10,
         "sum_assured":"VC"
      },
      {
         "code":"RC",
         "deductive":0,
         "sum_assured":"3000000"
      },
      {
         "code":"GM",
         "deductive":0,
         "sum_assured":"300000"
      },
      {
         "code":"RCE",
         "deductive":0,
         "sum_assured":"A"
      },
      {
         "code":"AV",
         "deductive":0,
         "sum_assured":"A"
      },
      {
         "code":"DL",
         "deductive":0,
         "sum_assured":"A"
      },
      {
         "code":"ERC",
         "deductive":0,
         "sum_assured":"A"
      }
      ]
    },
    quotesToEmail(){
      var result = [];
      this.filteredAseguradoras.map((e) => {
        result.push({
          insurance: e.insurer,
          price: e.price.toLocaleString(),
        });
      });
      return result
    }
  },
};
</script>
<style scoped>
.mb-32 {
  margin-bottom: -32px;
}
.gray {
  color: #697077;
}
.mb-20 {
  margin-bottom: -20px;
}
.mb-9 {
  margin-top: 25px;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active en <2.1.8 */ {
  opacity: 0;
  transform: translateY(
    -20px
  ); /* Ajusta la distancia de deslizamiento hacia abajo */
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 0.5s;
}
.slide-down-enter, .slide-down-leave-to /* .slide-down-leave-active en <2.1.8 */ {
  transform: translateY(-100%); /* Inicialmente fuera de la pantalla */
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
