<template>
  <v-container>
    <v-row style="padding: 0px 30px 0px 30px">
      <v-col cols="12">
        <h1>Alta de alianza</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="10">
        <v-stepper v-model="step" style="background-color: transparent">
          <v-stepper-header>
            <v-stepper-step class="stepper-style" :complete="step > 1" step="0">
              <div class="icon-text" @click="isNextStep(false, 1)">
                <v-icon color="#81CFE6" v-if="isStepCompleted(0)"
                  >mdi-album</v-icon
                >
                <v-icon color="#D9D9D9" v-else>mdi-circle-outline</v-icon>
                <span
                  :style="{ color: isStepCompleted(0) ? '#81CFE6' : '#C1C7CD' }"
                  >Registro de datos</span
                >
              </div>
            </v-stepper-step>
            <v-divider style="margin-bottom: 15px" color="#D9D9D9"></v-divider>
            <v-stepper-step class="stepper-style" :complete="step > 2" step="1">
              <div class="icon-text" @click="isNextStep(false, 2)">
                <v-icon color="#81CFE6" v-if="isStepCompleted(1)"
                  >mdi-album</v-icon
                >
                <v-icon color="#D9D9D9" v-else>mdi-circle-outline</v-icon>
                <span
                  :style="{ color: isStepCompleted(1) ? '#81CFE6' : '#C1C7CD' }"
                  >Carga de archivos</span
                >
              </div>
            </v-stepper-step>
            <v-divider style="margin-bottom: 15px" color="#D9D9D9"></v-divider>
            <v-stepper-step class="stepper-style" step="2">
              <div class="icon-text">
                <v-icon color="#81CFE6" v-if="isStepCompleted(2)"
                  >mdi-album</v-icon
                >
                <v-icon color="#D9D9D9" v-else>mdi-circle-outline</v-icon>
                <span
                  :style="{ color: isStepCompleted(2) ? '#81CFE6' : '#C1C7CD' }"
                >
                  Configuración de marca
                </span>
              </div>
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>

    <common-card>
      <div v-if="step === 1">
        <v-row style="padding: 20px 30px 0px 30px">
          <v-col cols="12">
            <h2>Registro de datos</h2>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align="center"
          style="padding: 0px 30px 20px 30px"
        >
          <v-col class="pa-0 pl-3" cols="3">
            <p>Nombre de la alianza</p>
          </v-col>
          <v-col class="pa-0 pr-3" cols="9"
            ><v-text-field
              name="nombreAlianza"
              v-model="usuarioAgente.nombreAlianza"
              label="Nombre alianza"
              :rules="generalRules"
              outlined
              primary
              dense
              color="#00a7e4"
            ></v-text-field
          ></v-col>
          <v-col class="pa-0 pl-3" cols="4">
            <p>¿A qué régimen perteneces?</p>
          </v-col>
          <v-col class="pa-0 pr-3" cols="8"
            ><v-autocomplete
              item-text="label"
              item-value="id"
              v-model="usuarioAgente.regimen"
              :items="regimen"
              :rules="generalRules"
              label="Régimen fiscal"
              outlined
              primary
              dense
              color="#00a7e4"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-if="mostrarPersonaMoral()" style="padding: 0px 30px 0px 30px">
          <v-col class="padding-col" cols="12" md="6">
            <v-text-field
              name="razonSocial"
              v-model="usuarioAgente.razonSocial"
              label="Razón Social"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="padding-col">
            <v-text-field
              id="rfc"
              v-model="usuario.rfcPersonaMoral"
              label="RFC"
              type="text"
              required
              placeholder="XAX010101XA0"
              :rules="rfcRulesMoral"
              @input="onInputToUpper"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row style="padding: 0px 30px 30px 30px">
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              name="name"
              label="Nombre(s)"
              :rules="generalRules"
              v-model="usuario.nombre"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              name="apellidoPaterno"
              label="Apellido Paterno"
              :rules="generalRules"
              v-model="usuario.apellidoPaterno"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              name="apellidoMaterno"
              label="Apellido Materno"
              v-model="usuario.apellidoMaterno"
              outlined
              primary
              color="#00a7e4"
              dense
              :rules="generalRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-autocomplete
              v-model="usuario.sexo"
              :items="sexoList"
              item-text="sexo"
              item-value="id"
              label="Género"
              required
              :rules="generalRules"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              v-model="usuario.fechaNacimiento"
              label="Fecha de Nacimiento"
              placeholder="dd/mm/aaaa"
              outlined
              primary
              color="#00a7e4"
              dense
              type="date"
              :rules="generalRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-autocomplete
              item-text="nombre"
              item-value="id"
              v-model="usuario.nacimientoEstadoId"
              :items="estados"
              :rules="generalRules"
              label="Estado de nacimiento"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              name="telefono"
              label="Teléfono  de  contacto"
              placeholder="##########"
              v-mask="'##########'"
              :rules="phoneRules"
              v-model="usuario.telefono"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-text-field
              label="Celular de contacto"
              placeholder="##########"
              v-mask="'##########'"
              v-model="usuario.celular"
              outlined
              primary
              color="#00a7e4"
              dense
              :rules="phoneRules"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="usuarioAgente.regimen != 2"
            cols="12"
            md="4"
            class="padding-col"
          >
            <v-text-field
              id="rfc"
              v-model="usuario.rfcRepresentanteLegal"
              label="RFC Representante legal"
              type="text"
              required
              placeholder="XAXX010101000"
              :rules="rfcRules"
              @input="onInputToUpper"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col v-else cols="12" md="4" class="padding-col">
            <v-text-field
              id="rfc"
              v-model="usuario.rfc"
              label="RFC"
              type="text"
              required
              placeholder="XAXX010101000"
              :rules="rfcRules"
              @input="onInputToUpper"
              outlined
              primary
              color="#00a7e4"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-autocomplete
              v-model="usuario.ramo"
              :items="ramoList"
              item-text="label"
              item-value="branchId"
              label="Ramo"
              multiple
              outlined
              primary
              color="#00a7e4"
              dense
              :rules="generalRules"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  style="padding: 1px; border-radius: 5px; height: 20px"
                  color="primary"
                  v-if="index < 3"
                >
                  <span style="padding: 3px; margin: 0">{{ item.label }}</span>
                </v-chip>
                <span v-if="index === 3" class="grey--text text-caption">
                  (+{{ usuario.ramo.length - 3 }} más)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="padding-col">
            <v-autocomplete
              v-model="usuario.canal"
              :items="canalList"
              label="Canal"
              item-text="label"
              item-value="channelId"
              outlined
              primary
              color="#00a7e4"
              dense
              :rules="generalRules"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-row v-for="(element, index) in usuario.emails" :key="index">
              <v-col class="padding-col" cols="12">
                <CommonEmailAutocomplete
                  @emailUpdate="(e) => (element.correo = e)"
                  :isDense="true"
                >
                </CommonEmailAutocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <DocumentosAlianza
        @file-selected="handleFileSelected"
        @update-upload-status="handleUploadStatus"
        :regimen="usuarioAgente.regimen"
        v-if="step === 2"
      />
    </common-card>

    <common-card style="margin-top: 36px; padding: 20px" v-if="step === 2">
      <AgregarAsesor :idAliance="idAliance" />
    </common-card>

    <common-card style="margin-top: 36px; padding: 20px" v-if="step === 3">
      <configuracion-marca-alta-alianza
        :idAliance="idAliance"
        :nameAlliance="usuarioAgente.nombreAlianza"
      />
    </common-card>

    <v-row v-if="step === 1">
      <v-col cols="12" style="text-align: end" class="pt-10 pr-0">
        <v-btn
          @click="setNewUser()"
          class="common-botton mr-4"
          :disabled="validador"
          dark
          width="200"
        >
          Siguiente
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="step === 2">
      <v-col cols="12" style="text-align: end" class="pt-10 pr-0">
        <v-btn
          :disabled="!allFilesUploaded"
          @click="uploadFiles"
          class="common-botton mr-4"
          dark
          width="200"
        >
          Continuar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mainAxios } from "../../../../mainAxios";
import axios from "axios";
import moment from "moment";
import RfcFacil from "rfc-facil";
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import CommonEmailAutocomplete from "../../../../components/commonComponents/CommonEmailAutocomplete.vue";
import DocumentosAlianza from "./DocumentosAlianza.vue";
import AgregarAsesor from "./AgregarAsesor.vue";
import ConfiguracionMarcaAltaAlianza from "./ConfiguracionMarcaAltaAlianza.vue";
export default {
  components: {
    CommonCard,
    CommonEmailAutocomplete,
    DocumentosAlianza,
    AgregarAsesor,
    ConfiguracionMarcaAltaAlianza,
  },
  data() {
    return {
      step: parseInt(localStorage.getItem("currentStep")) || 1,
      allFilesUploaded: false,
      prevStep: 1,
      selectedFiles: {},
      usuario: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        rfcPersonaMoral: "",
        rfcRepresentanteLegal: "",
        curp: "",
        sexo: "",
        fechaNacimiento: "",
        nacimientoEstadoId: 0,
        celular: "",
        telefono: "",
        correo: "",
        emails: [
          {
            correo: "",
            favorito: true,
          },
        ],
        status: 0,
        ramo: 0,
        canal: 0,
        estados: 0,
      },
      estados: [],
      usuarioAgente: {
        regimen: 2,
        razonSocial: "",
        nombreAlianza: "",
      },
      sexoList: [
        { id: 1, sexo: "Masculino" },
        { id: 2, sexo: "Femenino" },
      ],
      generalRules: [(v) => !!v || "Campo es necesario"],
      phoneRules: [
        (v) => !!v || "Campo es necesario",
        (v) => /^\d{10}$/.test(v) || "Mínimo 10 digitos",
        (v) =>
          !/^(.)\1{9}$/.test(v) ||
          "No se permiten secuencias de dígitos repetidos",
        (v) =>
          !["1111111111", "9999999999", "0000000000"].includes(v) ||
          "El número de teléfono no es válido",
      ],
      emailRules: [
        (v) => !!v || "E-mail es necesario",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail no es valido",
      ],
      rfcRules: [
        (v) =>
          v.length === 10 ||
          v.length === 12 ||
          v.length === 13 ||
          "RFC debe tener 10, 12 o 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^[A-Za-z\d]{10,13}$/.test(v) ||
          "RFC no es válido, asegúrate de tener la longitud correcta y utiliza caracteres alfanuméricos",
      ],
      rfcRulesMoral: [
        (v) => v.length === 12 || "RFC debe tener 12 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^[A-Za-z\d]{10,13}$/.test(v) ||
          "RFC no es válido, asegúrate de tener la longitud correcta y utiliza caracteres alfanuméricos",
      ],
      curpRules: [
        (v) => !!v || "CURP es necesario",
        (v) =>
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(
            v
          ) || "CURP no es valido recordar escribir en mayusculas",
      ],
      statusItems: ["Nuevo", "Activo"],
      regimen: [
        { id: 1, label: "Persona Moral" },
        { id: 2, label: "Persona Física" },
      ],
      canalList: [],
      ramoList: [],
      campaniaList: [],
      status: [
        { id: 1, name: "Prospecto Alianza" },
        { id: 2, name: "Nuevo" },
        { id: 3, name: "Activo" },
        { id: 4, name: "Inactivo" },
        { id: -1, name: "Baja" },
      ],
      canalItems: ["Agentes", "Directo", "Masivos"],
      tipoAgenteItems: [
        "Agente",
        "Comisionista",
        "Agente Apoderado",
        "Agente Promotoria",
      ],
      idAliance: localStorage.getItem("idAliance") || 0,
      archivo: null,
    };
  },

  mounted() {
    this.listas();
    if (this.idAliance) {
      console.log("ID de alianza recuperado:", this.idAliance);
    } else if (this.step > 1) {
      this.step = 1;
    }
    // this.cargarRoles();
    // window.dataLayer.push({
    //   event: "ITFY_crear_agente_v",
    //   no_cotizacion: undefined,
    //   asesor: localStorage.getItem("agenteMail"),
    //   correo: undefined,
    //   telefono: undefined,
    // });
  },

  watch: {
    step(newStep) {
      localStorage.setItem("currentStep", newStep);
    },
    "usuario.fechaNacimiento"() {
      this.actualizarRfc();
    },
    "usuario.nombre"() {
      this.actualizarRfc();
    },
    "usuario.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "usuario.apellidoMaterno"() {
      this.actualizarRfc();
    },
  },

  computed: {
    validador() {
      let valdadorSum = 0;
      this.usuario.nombre
        ? this.usuario.nombre.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.apellidoPaterno
        ? this.usuario.apellidoPaterno.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.telefono
        ? this.usuario.telefono.length > 9
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.emails[0].correo
        ? this.usuario.emails[0].correo.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.nacimientoEstadoId
        ? this.usuario.nacimientoEstadoId.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";
      this.usuario.rfc
        ? this.usuario.rfc.length > 0
          ? (valdadorSum = valdadorSum + 1)
          : ""
        : "";

      if (valdadorSum == 5) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    handleUploadStatus(status) {
      this.allFilesUploaded = status;
    },
    handleFileSelected({ documentId, file }) {
      this.$set(this.selectedFiles, documentId, file);
    },

    async uploadFiles() {
      this.allFilesUploaded = false;
      try {
        const formData = new FormData();

        // Añadir los archivos seleccionados al formData
        for (const documentId in this.selectedFiles) {
          if (
            Object.prototype.hasOwnProperty.call(this.selectedFiles, documentId)
          ) {
            formData.append("files", this.selectedFiles[documentId]);
          }
        }

        formData.append("tipo", "txt");
        formData.append("nombre", "Test Multiple Docs");

        const response = await axios.post(
          `https://api-dev.segurify.com/v1/alianza/documento/upload/${this.idAliance}`,
          // `https://api-dev.segurify.com/v1/alianza/documento/upload/80`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          }
        );
        if (response.data.id) {
          this.step = 3;
        }
      } catch (error) {
        console.error("Error al subir los archivos:", error);
      }
    },

    mostrarPersonaMoral() {
      if (this.usuarioAgente.regimen == 1) {
        return true;
      } else {
        return false;
      }
    },

    isNextStep(isNext, value) {
      this.prevStep = this.step;
      if (isNext) {
        this.setNewUser();
        this.step++;
      } else if (value <= this.step) {
        this.step = value;
      }
    },

    isStepCompleted(stepNumber) {
      return this.step > stepNumber;
    },

    correoFavoritoModificado(v) {
      this.usuario.correo = v;
    },

    actualizarRfc() {
      if (
        !this.usuario.nombre ||
        !this.usuario.apellidoPaterno ||
        !this.usuario.apellidoMaterno ||
        !this.usuario.fechaNacimiento
      )
        return;
      const arregloNacimiento = moment(this.usuario.fechaNacimiento)
        .format("DD/MM/YYYY")
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.usuario.nombre,
        firstLastName: this.usuario.apellidoPaterno,
        secondLastName: this.usuario.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.usuario.rfc = rfc;
      this.usuario.rfcRepresentanteLegal = rfc;
      this.usuario.password = this.usuario.rfc;
    },
    setNewUser() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          ContentType: "application/json",
        },
      };
      let dataUser = {
        email: this.usuario.emails[0].correo,
        companyName: this.usuarioAgente.razonSocial,
        nameAlliance: this.usuarioAgente.nombreAlianza,
        name: this.usuario.nombre,
        lastname: this.usuario.apellidoPaterno,
        secondLastname: this.usuario.apellidoMaterno,
        birthdate: this.usuario.fechaNacimiento,
        phone: this.usuario.telefono,
        cellphone: this.usuario.celular,
        taxId:
          this.usuario.regimen == 2
            ? this.usuario.rfc
            : this.usuario.rfcRepresentanteLegal,
        taxIdMoral: this.usuario.rfcPersonaMoral
          ? this.usuario.rfcPersonaMoral
          : null,
        cat_channel_id: this.usuario.canal,
        cat_state_id: this.usuario.nacimientoEstadoId,
        cat_gender_id: this.usuario.sexo,
        cat_regime_id: this.usuarioAgente.regimen,
        cat_branch_ids: this.usuario.ramo,
      };
      console.log(dataUser);
      mainAxios
        .post(`/v1/alliance`, dataUser, config)
        .then(({ data }) => {
          // Verifica si el status en el cuerpo de la respuesta es 200
          if (data.status === 200) {
            this.idAliance = data.data.alliance.id;
            localStorage.setItem("idAliance", this.idAliance);
            this.step++;
          }
        })
        .catch((error) => {
          // Manejando errores en el .catch para fallos de la solicitud (errores de red, etc.)
          this.$toast.error(error.response?.data?.data.result, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    listas() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/catalogo/alliance`, config).then((response) => {
        function corregirLabels(branchs) {
          return branchs.map((branch) => {
            if (branch.label === "Danios") {
              branch.label = "Daños";
            }
            return branch;
          });
        }
        corregirLabels(response.data.branchs);
        this.ramoList = response.data.branchs;
        this.canalList = response.data.channels;
      });
      mainAxios.get(`/v1/campania/list`, config).then((response) => {
        this.campaniaList = response.data;
      });

      mainAxios.get(`/v1/agente/landing/list`, config).then(({ data }) => {
        this.landings = data;
      });
      mainAxios.get(`/v1/catalogo/estado/nombres`, config).then(({ data }) => {
        this.estados = data;
      });
    },

    onInputToUpper() {
      this.usuario.rfc = this.usuario.rfc.toUpperCase();
    },

    descargarTemplate() {
      // Lógica para descargar el template CSV
      const link = document.createElement("a");
      link.href = "ruta/al/template.csv";
      link.download = "template.csv";
      link.click();
    },
  },
};
</script>

<style>
.mayusculas {
  text-transform: uppercase;
}
.cursor-pointer {
  cursor: pointer;
}
.icon-text {
  width: auto;
  color: #d9d9d9;
}

.stepper-style {
  padding: 0;
}
h2 {
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 700;
}
h1 {
  font-size: 26px;
  font-family: "Montserrat";
  font-weight: 700;
}
p {
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 0px;
  padding: 0;
}
.padding-col {
  padding: 0px 12px 0px 12px;
}
.radios {
  margin-top: 20px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 18px;
}
</style>
