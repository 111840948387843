
<template>
  <div class="main-border">
    <v-row class="pb-5">
      <v-col
        cols="6"
        :md="globalConfig.showQuotesDashboard ? (noShowPolices ?  4 :  3) : noShowPolices ? 4: 6"
        :class="
          $route.params.vista_id == 4 ? 'text-center active-color' : 'text-center inactive-color'
        "
        v-if="globalConfig.showQuotesDashboard"
        @click="$router.push('/main-hub/4')"
      >
          <span
            :class="
              $route.params.vista_id == 4
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          >
          Mis Cotizaciones</span>
      </v-col>
      <v-col
        cols="6"
        :md="globalConfig.showQuotesDashboard ? 3 : 4"
        :class="
          $route.params.vista_id == 1 ? 'text-center active-color' : 'text-center inactive-color'
        "
        @click="$router.push('/main-hub/1')"
      >
          <span
            :class="
              $route.params.vista_id == 1
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          > Mis solicitudes</span>
      </v-col>
      <v-col
        cols="6"
        :md="globalConfig.showQuotesDashboard ? 3 : 4"
        :class="
          $route.params.vista_id == 2 ? 'text-center active-color' : 'text-center inactive-color'
        "
        @click="$router.push('/main-hub/2')"
        v-if="noShowPolices"
      >
          <span
            :class="
              $route.params.vista_id == 2
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          > Mis Pólizas</span>
      </v-col>
      <v-col
        cols="6"
        :md="globalConfig.showQuotesDashboard ? 3 : 4"
        :class="
          $route.params.vista_id == 3 ? 'text-center active-color' : 'text-center inactive-color'
        "
        @click="$router.push('/main-hub/3')"
      >
          <span
            :class="
              $route.params.vista_id == 3
                ? 'text-header-step-active'
                : 'text-header-step-no-active'
            "
          >Mis Clientes</span>
      </v-col>
    </v-row>
    <div>
      <div v-if="isAlianza ? tab == 1 : $route.params.vista_id == 1">
        <solicitudes-index></solicitudes-index>
      </div>
      <div v-if="isAlianza ? tab == 2 : $route.params.vista_id == 2">
        <polizas-index></polizas-index>
      </div>
      <div v-if="isAlianza ? tab == 3 : $route.params.vista_id == 3">
        <clientes-index></clientes-index>
      </div>
      <div v-if="isAlianza ? tab == 4 : $route.params.vista_id == 4">
        <cotizaciones-index></cotizaciones-index>
      </div>
      <div v-if="isAlianza ? tab == 5 : $route.params.vista_id == 5">
        <TablaUsuarios modulo="AGENTE" :alianza_id="alianza_id"/>
      </div>
    </div>
  </div>
</template>
<script>
import SolicitudesIndex from "./solicitudes/Dasboard/SolicitudesIndex.vue";
import PolizasIndex from "./polizas/Dashboard/PolizasIndex.vue";
import ClientesIndex from "./clientes/Dashboard/ClientesIndex.vue";
import CotizacionesIndex from "./cotizaciones/Dasboard/CotizacionesIndex.vue";
import TablaUsuarios from '../../../views/pages/administrador/usuarios/TablaUsuarios.vue';

export default {
  components: {
    SolicitudesIndex,
    PolizasIndex,
    ClientesIndex,
    CotizacionesIndex,
    TablaUsuarios
  },
  props: {
    isAlianza: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 1,
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  methods: {
    changeView() {
      return 1;
    },
    calculateClass(valTab) {
      if (this.isAlianza)
        return this.tab == valTab
          ? "text-center active-color"
          : "text-center inactive-color";
      else
        return this.$route.params.vista_id == valTab
          ? "text-center active-color"
          : "text-center inactive-color";
    },
    calculateHeaderClass(valTab) {
      if (this.isAlianza)
        return this.tab == valTab
          ? "text-header-step-active"
          : "text-header-step-no-active";
      else
        return this.$route.params.vista_id == valTab
          ? "text-header-step-active"
          : "text-header-step-no-active";
    },
    handleClick(tab) {
      if (this.isAlianza) {
        if (this.tab != tab) this.tab = tab;
      }
      else this.$router.push("/main-hub/" + tab);
    },
    calculateColumnWidth() {
    return Math.floor(100 / 5); 
  },
  },
  computed:{
    noShowPolices(){
      if(this.rol.includes('INBOUND'))
        return false
      else return true
    }
  },
  mounted(){
    this.isAlianza ? this.tab = 5 : this.tab = 4;
    this.isAlianza ? this.alianza_id = this.$route.params.alianza_id : ''
  }
};
</script>
