<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mx-3 mt-3">
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="carBrand.brand"
            :items="brand"
            :item-text="(item) => item"
            :item-value="(item) => item"
            label="Marca*"
            :rules="requiredRule"
            outlined
            @change="clearInputs()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="carBrand.year"
            :items="years"
            :item-text="(item) => item"
            :item-value="(item) => item"
            label="Año*"
            :rules="requiredRule"
            @change="getVersions()"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" v-if="versions.length > 0">
          <v-autocomplete
            v-model="carBrand.model"
            :items="versions"
            item-text="description"
            :item-value="(item) => item"
            label="Modelo*"
            :rules="requiredRule"
            @change="setInsures($event)"
            outlined
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mx-3 mt-3" v-if="!isAgente">
        <v-col cols="4">
          <v-autocomplete
            label="Agente"
            :items="agentesConRamos"
            item-text="nombre"
            item-value="id"
            v-model="agenteId"
            placeholder="Requerido"
            outlined
            color="#00a7e4"
            :rules="[$rules.required]"
            no-data-text="Sin agentes para mostrar"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mainAxios } from "@/mainAxios.js";
export default {
  name: "FormularioMarcasVehiculo",
  props: {
    carBrand: Object,
    token: String,
    insurers: Array,
  },
  data() {
    return {
      requiredRule: [(v) => !!v || "El campo es requerido"],
      trackingId: "",
      brand: [],
      versions: [],
      years: this.generateLast60Years(),
      agenteId: null,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      agentes: [],
      ramosIdsUsuario: [],
      canalesUsuario: [],
    };
  },

  computed: {
    agentesConRamos() {
      return this.agentes.filter(
        (agente) => this.tieneRamos(agente) && this.tieneCanales(agente)
      );
    },

    isAgente() {
      return this.rol == "AGENT";
    },
  },

  mounted() {
    if (this.rol == "AGENT") this.agenteId = localStorage.id;
    else {
      this.getAgentes();
      this.getUsuarios();
    }
  },

  methods: {
    getBrands() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": this.token,
        },
      };
      mainAxios
        .get("/v1/cotizacion-ws/auto/catalogo/marcas", config)
        .then(({ data: { data } }) => {
          this.brand = data.marcas;
        });
    },

    getVersions() {
      this.$emit("showLoading");
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "inter-token": this.token,
        },
      };
      mainAxios
        .get(
          `/v1/cotizacion-ws/auto/catalogo/versiones/${this.carBrand.brand}/${this.carBrand.year}`,
          config
        )
        .then(({ data: { data } }) => {
          this.versions = data.versiones;
          this.$emit("hideLoading");
        });
    },
    generateLast60Years() {
      const currentYear = new Date().getFullYear();
      let years = [];
      for (let i = 0; i <= 60; i++) {
        years.push(currentYear - i);
      }
      return years;
    },
    setInsures(event) {
      this.$emit("setInsurers", event.insurers);
    },
    clearInputs() {
      this.versions = [];
      this.carBrand.year = 0;
      this.$emit("setInsurers", []);
    },

    getUsuarios() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/agente/uuid/${localStorage.agenteUuid}`, config)
        .then((response) => {
          this.ramosIdsUsuario = response.data.ramos;
          this.canalesUsuario = response.data.canales || [];
        });
    },

    tieneRamos(agente) {
      const ramosAgente = agente.ramosList.map((ramo) => ramo.id);
      return this.ramosIdsUsuario.some((id) => ramosAgente.includes(id));
    },

    tieneCanales(agente) {
      const tieneCanalesDelUsuario = !!agente.canalList.some((canal) =>
        this.canalesUsuario.some((canalUsuario) => canalUsuario == canal.id)
      );

      return tieneCanalesDelUsuario;
    },

    getAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList ? element.canalesList : [],
            ramosList: element.ramosList ? element.ramosList : [],
            alianzaId: element.alianzaId ? element.alianzaId : null
          });
        });
      });
    },
    getCurrentAlianzaId(){
      var currentAgente = this.agentes.find(
          (e) => e.id == this.agenteId
        );
      return  currentAgente ? currentAgente.alianzaId : null
    },
  },

  watch: {
    token(newToken) {
      if (newToken) {
        this.getBrands();
      }
    },
    "carBrand.year"(newValue) {
      if (newValue > 0) {
        this.getVersions();
      }
    },

    agenteId(v) {
      !!v && this.$emit("agenteIdUpdated", v), this.$emit("alianzaIdUpdated", this.getCurrentAlianzaId());
    },
  },
};
</script>
<style scoped></style>
